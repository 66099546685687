// NoWorkspaceFound.js
import React from 'react';
import { Box, Text, useColorModeValue, Icon } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { keyframes } from '@emotion/react';

const typing = keyframes`
  from { width: 0; }
  to { width: 40ch; } /* Adjust this width to match the length of your text */
`;

const blink = keyframes`
  50% { border-color: transparent; }
`;

const NoWorkspaceFound = () => {
  const color = useColorModeValue('purple.500', 'purple.200');
  
  return (
    <Box 
      display="flex" 
      flexDirection="column"
      alignItems="center" 
      justifyContent="center" 
      height="100vh" 
      bg={useColorModeValue('white', 'gray.800')}
      textAlign="center"
      p={4}
    >
      <Icon as={WarningIcon} w={10} h={10} color="red.500" mb={4} />
      <Text 
        fontSize="2xl" 
        fontWeight="bold"
        color={color}
        overflow="hidden"
        borderRight=".6em solid orange" /* Cursor effect */
        whiteSpace="nowrap"
        letterSpacing=".15em"
        animation={`${typing} 1.8s steps(40, end), ${blink} .75s step-end infinite`}
        width="40ch" /* Set to match the full length of the text */
        mb={4}
      >
        No Organization has been assigned to you.
      </Text>
      <Text 
        fontSize="lg" 
        color={useColorModeValue('gray.700', 'gray.300')}
      >
        Looks like you're not in any organization yet. Give your manager a nudge to send you an invite so you can join the fun!
      </Text>
    </Box>
  );
};

export default NoWorkspaceFound;
