import createRoutes from '../../utils/utilities';
import DevasProject from './DevasProjects';
import DevasPage from './DevasUI';
import DevasBrdPage from './DevasCreateBRDCode';
import StartDevasPage from './DevasInit';

export const devasRoutes = {
    devasProject: {
        name: "Projects",
        path: "/devas/projects",
        component: DevasProject,
        private: true
    },
    devasPage: {
        name: "Devas",
        path: "/devas/:projectId",
        component: DevasPage,
        private: true
    },
    devasBrdPage: {
        name: "Devas BRD",
        path: "/devas/coder",
        component: DevasBrdPage,
        private: true
    },
    startDevasPage: {
        name: "Start Devas",
        path: "/start-devas",
        component: StartDevasPage,
        private: true
    },
};

const DevasRoutes = createRoutes({ routes: devasRoutes });
export default DevasRoutes;