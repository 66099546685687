import React from "react";
import mermaid from "mermaid";
import { useEffect, useRef } from "react";

mermaid.initialize({
  startOnLoad: true,
  theme: "default",
  securityLevel: "loose",
  fontFamily: "monospace",
});

const MermaidDiagram = ({ query, id_index=1 }) => {
  const mermaidRef = useRef(null);

  useEffect(() => {
      const initializeMermaid = async () => {
          if (mermaidRef.current) {
              mermaidRef.current.innerHTML = query;
              const { svg, bindFunctions } = await mermaid.render(`mermaid-diagram-${id_index}`, query);
              mermaidRef.current.innerHTML = svg;
              bindFunctions?.(mermaidRef.current);
          }
      };

      initializeMermaid();

      // Clean up mermaid instance when unmounting; doing nothing at the momemt
      return () => {

      };
  }, [query]);

  return <div id={id_index} ref={mermaidRef}></div>;
};

export default MermaidDiagram;
