import React, { useEffect, useState } from 'react';
import {
    Box, Button, Heading, Stack, Table, Tbody, Td, Th, Thead, Tr,
    useToast, Input, FormControl, FormLabel, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Text, Flex,
    useColorMode, IconButton, HStack, Link, Avatar, Tooltip
} from '@chakra-ui/react';
import { FaPlus, FaEdit, FaTrash, FaEye } from 'react-icons/fa';
import { baseDevasUrl, baseUrl } from '../../utils/constants';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CustomBreadcrumb from '../common/CustomBreadcrumb';
import { genericRoutes } from '../routes';
import { documentRoutes } from './routes';
import { formatDate } from '../../utils/utilities';

const DocumentId = 2

const DocumentsProjects = () => {
    const breadcrumbItems = [
        { label: genericRoutes.products.name, path: genericRoutes.products.path, isCurrentPage: false },
        { label: documentRoutes.documentsProjects.name, path: documentRoutes.documentsProjects.path, isCurrentPage: true }
    ];
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [productId,setproductId] = useState(DocumentId);
    const [newProject, setNewProject] = useState({ name: '',product_id: productId, org_id: 0, repo_url: '', reference_repo_url: '', created_by: localStorage.getItem("email") });
    const [editProject, setEditProject] = useState(null);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { setColorMode } = useColorMode();
    setColorMode("light");

    useEffect(() => {
        fetchProjects();
    }, [currentPage]);

    const fetchProjects = async () => {
        try {
            console.log("URL",`${baseDevasUrl}projects?user_email=${localStorage.getItem("email")}&product_id=${productId}`)
            const response = await fetch(`${baseDevasUrl}projects?user_email=${localStorage.getItem("email")}&product_id=${productId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            setProjects(data.data.projects);
            setTotalPages(Math.ceil(data.data.projects.length / 8)); // Assuming 5 projects per page
        } catch (error) {
            console.log("Error while fetching projects: ", error);
        }
    };

    const createProject = async () => {
        try {
            const response = await fetch(`${baseDevasUrl}projects`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newProject)
            });
            const data = await response.json();
            if (data.success) {
                toast({ title: data.data.message, status: "success" });
                fetchProjects();
                setNewProject({ name: '', product_id: productId, org_id: 0, repo_url: '', reference_repo_url: '', created_by: '' });
                onClose();
            } else {
                console.log("Error while creating project");
            }
        } catch (error) {
            console.log("Error while creating project");
        }
    };


    const updateProject = async () => {
        try {
            const response = await fetch(`${baseDevasUrl}projects/${editProject.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: editProject.name })
            });
            const data = await response.json();
            if (data.success) {
                toast({ title: "Project updated successfully!", status: "success" });
                fetchProjects();
                setEditProject(null);
                onEditClose();
            } else {
                console.log("Error while updating project");
            }
        } catch (error) {
            console.log("Error while updating project");
        }
    };

    const deleteProject = async (id) => {
        try {
            const response = await fetch(`${baseDevasUrl}projects/${id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            if (data.success) {
                toast({ title: "Project deleted successfully!", status: "success" });
                fetchProjects();
            } else {
                console.log("Error while deleting project");
            }
        } catch (error) {
            console.log("Error while deleting project");
        }
    };

    return (
        <Box p={5} fontFamily="sans-serif">
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Heading color="purple.800">Projects</Heading>
                <Button onClick={onOpen} leftIcon={<FaPlus />} colorScheme="purple">New Project</Button>
            </Flex>
            <CustomBreadcrumb items={breadcrumbItems}></CustomBreadcrumb>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Project</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={3}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input value={newProject.name} placeholder="Project name" focusBorderColor="purple.400" onChange={(e) => setNewProject({ ...newProject, name: e.target.value })} />
                            </FormControl>
                            {/* <FormControl>
                                <FormLabel>Repository URL</FormLabel>
                                <Input value={newProject.repo_url} focusBorderColor="purple.400" onChange={(e) => setNewProject({ ...newProject, repo_url: e.target.value })} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Reference Repository URL</FormLabel>
                                <Input value={newProject.reference_repo_url} focusBorderColor="purple.400" onChange={(e) => setNewProject({ ...newProject, reference_repo_url: e.target.value })} />
                            </FormControl> */}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createProject} colorScheme="purple">Create Project</Button>
                        <Button onClick={onClose} ml={3}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isEditOpen} onClose={onEditClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Project</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input value={editProject?.name || ''} focusBorderColor="purple.400" onChange={(e) => setEditProject({ ...editProject, name: e.target.value })} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={updateProject} colorScheme="purple">Update Project</Button>
                        <Button onClick={onEditClose} ml={3}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box>
            <Table variant="simple" mt={10}>
                <Thead>
                    <Tr bgColor="purple.100">
                        <Th color="purple.500" style={{fontWeight: "800", fontSize: "14px"}}>Name</Th>
                        <Th color="purple.500" style={{fontWeight: "800", fontSize: "14px"}}>Start Date</Th>
                        <Th color="purple.500" style={{fontWeight: "800", fontSize: "14px"}}>Owner</Th>
                        <Th color="purple.500" style={{fontWeight: "800", fontSize: "14px"}}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {projects.slice((currentPage - 1) * 8, currentPage * 8).map((project, index) => (
                    <Tr key={index} _hover={{ bg: "purple.50" }}>
                      <Td w="40%" style={{ fontWeight: "500" }}>
                        <Link
                          href={`/doccreator/project/${project.project_id}`}
                          _hover={{ textDecoration: "none", color: "purple.500" }}
                        >
                          {project.name.charAt(0).toUpperCase() + project.name.slice(1)}
                        </Link>
                      </Td>
                      <Td w="20%">{formatDate(project.created_at)}</Td>
                      <Td w="20%">
                        <Tooltip label={project.created_by} aria-label="Email tooltip">
                          <Avatar
                            name={project.created_by.charAt(0).toUpperCase()}
                            bg="teal.500"
                            size="sm"
                            ml="10px"
                          />
                        </Tooltip>
                      </Td>
                      <Td w="20%">
                        <Button size="sm" variant={"outline"} colorScheme="blue" mr={2} onClick={() => window.location.href=`/doccreator/project/${project.project_id}`}><FaEye /></Button>
                        <Button size="sm" variant={"outline"} colorScheme="green" mr={2} onClick={() => { setEditProject(project); onEditOpen(); }}><FaEdit /></Button>
                        <Button size="sm" variant={"outline"} colorScheme="red" onClick={() => deleteProject(project.id)}><FaTrash /></Button>
                      </Td>
                    </Tr>
                    ))}
                </Tbody>
            </Table>
            <HStack mt="4" justifyContent="center">
                <IconButton 
                    
                    icon={<ChevronLeftIcon />}
                    aria-label="Previous Page"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    isDisabled={currentPage === 1}
                    bgColor="purple.100"
                    _hover={{bgColor:"purple.200"}}
                />
                <Text>Page {currentPage} of {totalPages}</Text>
                <IconButton
                    icon={<ChevronRightIcon />}
                    aria-label="Next Page"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                    bgColor="purple.100"
                    _hover={{bgColor:"purple.200"}}
                />
            </HStack>
            </Box>
        </Box>
    );
};

export default DocumentsProjects;
