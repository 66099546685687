import { baseCerebrumUrl, roleHierarchy } from "./constants";
import { PrivateRoute } from '../comp/Auth/OAuth';
import { Route } from 'react-router-dom';
import React from "react";

export function allStorage() {

    let archive = {}, // Notice change here
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        if (keys[i] !== "token" && keys[i] !== "email" && keys[i] !== "current_extension_id" && keys[i] !== "chakra-ui-color-mode" && keys[i] !== "login_type") {
            archive[keys[i]] = localStorage.getItem(keys[i]);
        }
    }

    return archive;
}

export const updateData = async () => {
    const data = allStorage();
    const resp = await fetch(`${baseCerebrumUrl}extensions?row_id=` + localStorage.getItem("current_extension_id"), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
}

export const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};


export const isManager = () => {
    const token = JSON.parse(atob(localStorage.getItem("token")));
    const roleSlug = token?.session?.passport?.user?.roleSlug;
    return roleSlug === "engg-manager" || roleSlug === "org-owner";
}

export const getSessionDetails = () => {
    const token = JSON.parse(atob(localStorage.getItem("token")));
    return token;
}


export const getOrgNameById = (workspace, orgId) => {
    const org = workspace.find(item => item.orgId === orgId);
    return org ? org.orgName : null;
}

export const canInvite = (currentRole, invitedRole) => {
    if (currentRole) {
        const allowedRoles = roleHierarchy[currentRole];
        return allowedRoles.includes(invitedRole);
    }
}

const createRoutes = ({ routes }) => {
    return (
        <>
            {Object.entries(routes).map(([key, route]) => {
                if (route.isActive === undefined || route.isActive === true) {
                    if (route.private) {
                        return <Route key={key} path={route.path} element={<PrivateRoute component={route.component} />} />
                    } else {
                        return <Route key={key} path={route.path} element={<route.component />} />
                    }
                }
            })}
        </>
    );
};

export default createRoutes;

export const appendQueryParams = (url, params) => {
    const urlObj = new URL(url);
    Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]));
    return urlObj.toString();
};


export function handleRedirection(redirectUri, token, navigate) {
    if (redirectUri) {
        try {
            // const parsedUrl = new URL(redirectUri);
            // if (parsedUrl.protocol === 'vscode:') {
            //     const urlWithParams = appendQueryParams(redirectUri, { token });
            //     window.location.href = urlWithParams;
            //     return;
            // }
            const urlWithParams = appendQueryParams(redirectUri, { token });
            window.location.href = urlWithParams;
            return;
        } catch (e) {
            console.error("Invalid redirect URI", e);
        }
    }
    // TODO: Handle default navigation 
    // navigate('/products');
    navigate('/org-selection');
}

export function formatDate(dateString) {
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
    console.log(formattedDate);
    return formattedDate
}

export function validatePassword(password) {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/; // Checks for at least one uppercase letter
    const hasLowerCase = /[a-z]/; // Checks for at least one lowercase letter
    const hasDigits = /\d/; // Checks for at least one digit
    const hasSymbols = /[\W_]/; // Matches any non-word character or underscore

    return (
        password.length >= minLength &&
        hasUpperCase.test(password) &&
        hasLowerCase.test(password) &&
        hasDigits.test(password) &&
        hasSymbols.test(password)
    );
}