import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

const CustomBreadcrumb = ({ items }) => {
  // Function to replace path params for a specific item
  const replacePathParams = (path, params) => {
    let formattedPath = path;
    Object.keys(params).forEach(key => {
      formattedPath = formattedPath.replace(`:${key}`, params[key]);
    });
    return formattedPath;
  };

  return (
    <Breadcrumb mb={4} separator=">">
      {items.map((item, index) => {
        const path = item.pathParams ? replacePathParams(item.path, item.pathParams) : item.path;
        return (
          <BreadcrumbItem 
            key={index} 
            isCurrentPage={item.isCurrentPage} 
            fontWeight={item.isCurrentPage ? 'bold' : 'normal'}
            color={item.isCurrentPage ? "green.400" : "green.600"} // Adjust color based on current page status
          >
            {item.isCurrentPage ? (
              <span pl={1} pr={1} borderRadius={5} style={{cursor:"pointer"}}>
                {index === 0 ? <FontAwesomeIcon icon={faHouse}/> : item.label}
              </span>
            ) : (
              <BreadcrumbLink 
                as={Link} 
                to={path}
                textDecoration="none"
                pl={1}
                pr={1}
                borderRadius={5}
                _hover={{ color: "green.800", textDecoration: "none" }}
              >
                {index === 0 ? <FontAwesomeIcon icon={faHouse}/> : item.label}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
export default CustomBreadcrumb;