import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import * as d3 from "d3";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  GridItem,
  IconButton,
  Text,
  Heading,
  Flex,
  VStack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { FaSearchPlus, FaSearchMinus } from "react-icons/fa";
import MermaidDiagram from "../common/MermaidDiagram";
import { graph, baseIngestionUrl } from "../../utils/constants"; // Ensure you have the correct path for your graph data.
import { genericRoutes } from "../routes";
import { reviewPilotRoutes } from "../ReviewPilot/routes";
import { visualizeGraphRoutes } from "./routes";
import CustomBreadcrumb from "../common/CustomBreadcrumb";

const D3Graph = () => {
  let { repoId } = useParams();
  const breadcrumbItems = [
    {
      label: genericRoutes.products.name,
      path: genericRoutes.products.path,
      isCurrentPage: false,
    },
    {
      label: reviewPilotRoutes.prRepositories.name,
      path: reviewPilotRoutes.prRepositories.path,
      isCurrentPage: false,
    },
    {
      label: visualizeGraphRoutes.visualizeGraph.name,
      path: visualizeGraphRoutes.visualizeGraph.path,
      pathParams: { repoId },
      isCurrentPage: true,
    },
  ];
  const svgRef = useRef(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const zoomRef = useRef(null); // Ref to store the zoom behavior
  const width = 1100;
  const height = 650;
  const location = useLocation();

  useEffect(() => {
    fetchGraphDetails();
    return () => {
      // Clean up to prevent memory leaks when the component unmounts
      // svg.selectAll('*').remove();
    };
  }, []);

  const fetchGraphDetails = async () => {
    try {
      const response = await fetch(
        `${baseIngestionUrl}/get-repo-graph?repo_id=${repoId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_pat: localStorage.getItem("adoPat") }),
          credentials: "include",
        }
      );
      const data = await response.json();
      loadGraph(data.data);
    } catch (error) {
      console.error("Error fetching graph:", error);
    } finally {
      // setLoading(false);
    }
  };

  const loadGraph = (graph) => {
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    svg.selectAll("*").remove();
    const g = svg.append("g");

    // Define arrowhead marker
    svg
      .append("defs")
      .append("marker")
      .attr("id", "arrowhead")
      .attr("viewBox", "-0 -5 10 10")
      .attr("refX", 27) // Adjust this value to place the arrowhead correctly
      .attr("refY", 0)
      .attr("orient", "auto")
      .attr("markerWidth", 15)
      .attr("markerHeight", 15)
      .attr("xoverflow", "visible")
      .append("svg:path")
      .attr("d", "M 0,-5 L 10 ,0 L 0,5")
      .attr("fill", "none")
      .style("stroke", "#999");

    // Define a rotated arrowhead marker for self-links
    // svg
    //   .append("defs")
    //   .append("marker")
    //   .attr("id", "arrowhead-self")
    //   .attr("viewBox", "-0 -5 10 10")
    //   .attr("refX", 10) // Adjust this value for the self-loop arrowhead placement
    //   .attr("refY", 0)
    //   .attr("orient", "auto")
    //   .attr("markerWidth", 15)
    //   .attr("markerHeight", 15)
    //   .attr("xoverflow", "visible")
    //   .append("svg:path")
    //   .attr("d", "M 0,-5 L 10 ,0 L 0,5")
    //   .attr("fill", "none")
    //   .style("stroke", "#999")
    //   .attr("transform", "rotate(45)");
    
      svg
      .append("defs")
      .append("marker")
      .attr("id", "arrowhead-self")
      .attr("viewBox", '-5 -5 20 20')
      .attr("refX", 10) // Adjust this value to place the arrowhead correctly
      .attr("refY", 0)
      .attr("orient", "auto")
      .attr("markerWidth", 15)
      .attr("markerHeight", 15)
      .attr("xoverflow", "visible")
      .append("svg:path")
      .attr("d", "M 0,-5 L 10 ,0 L 0,5")
      .attr("fill", "none")
      .style("stroke", "#999")
      .attr("transform", "rotate(90)");

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 5])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });

    svg.call(zoom);
    zoomRef.current = zoom; // Store the zoom behavior in the ref

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const simulation = d3
      .forceSimulation(graph.nodes)
      .force(
        "link",
        d3
          .forceLink(graph.links)
          .id((d) => d.id)
          .distance(150)
      )
      .force("charge", d3.forceManyBody().strength(-400))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // const link = g
    //   .append("g")
    //   .attr("class", "links")
    //   .selectAll("line")
    //   .data(graph.links)
    //   .enter()
    //   .append("line")
    //   .attr("class", "link")
    //   .style("stroke", "#999")
    //   .style("stroke-opacity", 0.6)
    //   .style("stroke-width", 1.5)
    //   .attr("marker-end", "url(#arrowhead)");

    // // Curved links
    const link = g
      .append("g")
      .attr("class", "links")
      .selectAll("path")
      .data(graph.links)
      .enter()
      .append("path")
      .attr("class", "link")
      .style("stroke", "#999")
      .style("stroke-opacity", 0.6)
      .style("stroke-width", 1.5)
      .attr("fill", "none")
      .attr('marker-end', d => {
        // Use the rotated arrowhead marker for self-links, otherwise use the normal marker
        return (d.source.id === d.target.id) ? 'url(#arrowhead-self)' : 'url(#arrowhead)';
      });

    const linkLabel = g
      .append("g")
      .attr("class", "link-labels")
      .selectAll("text")
      .data(graph.links)
      .enter()
      .append("text")
      .attr("class", "link-label")
      .attr("dy", -3)
      .attr("text-anchor", "middle")
      .attr("font-size", 12)
      .attr("fill", "#555")
      .text((d) => d.type);

    const node = g
      .append("g")
      .attr("class", "nodes")
      .selectAll("g")
      .data(graph.nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .on("click", (_, d) => showNodeProperties(d)) // Ensure the event handler receives correct data
      .call(
        d3
          .drag() // Add drag behavior
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    node
      .append("circle")
      .attr("r", 38)
      .attr("fill", (d) => color(d.labels[0]))
      .style("stroke", "rgba(255, 255, 255, 0.4)")
      .style("stroke-width", 2)
      .style("cursor", "pointer")
      .on("mouseover", function () {
        d3.select(this)
          .transition()
          .duration(300)
          .style("stroke", "#ff6347")
          .style("stroke-width", 4);
      })
      .on("mouseout", function () {
        d3.select(this)
          .transition()
          .duration(300)
          .style("stroke", "rgba(255, 255, 255, 0.4)")
          .style("stroke-width", 2);
      });

    node
      .append("text")
      .attr("dy", 5)
      .attr("text-anchor", "middle")
      .style("fill", "#fff")
      .style("pointer-events", "none")
      .style("font-weight", "bold")
      .style("font-size", "12px")
      .text((d) => d.properties.name)
      .each(function (d) {
        const text = d3.select(this);
        const textLength = text.node().getComputedTextLength();
        const textContent = text.text();
        const maxLength = 80; // Max pixel width allowed for the text

        if (textLength > maxLength) {
          const charsToFit =
            Math.floor((textContent.length * maxLength) / textLength) - 3;
          text.text(`${textContent.slice(0, charsToFit)}...`);
        }
      });

    simulation.on("tick", () => {
      // link
      //   .attr("x1", (d) => d.source.x)
      //   .attr("y1", (d) => d.source.y)
      //   .attr("x2", (d) => d.target.x)
      //   .attr("y2", (d) => d.target.y);

      // Curved links
      // link.attr('d', d => {
      //   const dx = d.target.x - d.source.x;
      //   const dy = d.target.y - d.source.y;
      //   const dr = Math.sqrt(dx * dx + dy * dy); // Calculate distance for the arc

      //   // Use "A" path command for the arc, "0,0 1" means a clockwise arc
      //   return `M${d.source.x},${d.source.y}A${dr},${dr} 0 0,1 ${d.target.x},${d.target.y}`;
      // });

      //Self curved links
      link.attr("d", function (d) {
        var x1 = d.source.x,
          y1 = d.source.y,
          x2 = d.target.x,
          y2 = d.target.y,
          dx = x2 - x1,
          dy = y2 - y1,
          dr = Math.sqrt(dx * dx + dy * dy),
          // Defaults for normal edge.
          // drx = dr,
          // dry = dr,
          drx = 0,
          dry = 0,
          xRotation = 0, // degrees
          largeArc = 0, // 1 or 0
          sweep = 1; // 1 or 0

        // Self edge.
        if (x1 === x2 && y1 === y2) {
          // Fiddle with this angle to get loop oriented.
          xRotation = -45;

          // Needs to be 1.
          largeArc = 1;

          // Change sweep to change orientation of loop.
          // sweep = 0;

          // Make drx and dry different to get an ellipse
          // instead of a circle.
          drx = 50;
          dry = 20;

          // For whatever reason the arc collapses to a point if the beginning
          // and ending points of the arc are the same, so kludge it.
          x2 = x2 + 1;
          y2 = y2 + 1;
        }

        return (
          "M" +
          x1 +
          "," +
          y1 +
          "A" +
          drx +
          "," +
          dry +
          " " +
          xRotation +
          "," +
          largeArc +
          "," +
          sweep +
          " " +
          x2 +
          "," +
          y2
        );
      });

      linkLabel
        .attr("x", (d) => (d.source.x + d.target.x) / 2)
        .attr("y", (d) => (d.source.y + d.target.y) / 2);

      node.attr("transform", (d) => `translate(${d.x},${d.y})`);
    });

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    function showNodeProperties(d) {
      setSelectedNode(d);
    }
  };

  const handleZoomIn = () => {
    const svg = d3.select(svgRef.current);
    const zoom = zoomRef.current;
    svg.transition().duration(250).call(zoom.scaleBy, 1.3);
  };

  const handleZoomOut = () => {
    const svg = d3.select(svgRef.current);
    const zoom = zoomRef.current;
    svg
      .transition()
      .duration(250)
      .call(zoom.scaleBy, 1 / 1.3);
  };

  return (
    <Box bg="gray.100" minH="100vh" p={6}>
      {/* <Heading as="h1" size="lg" mb={6} textAlign="center" color="purple.600">
        Interactive Graph
      </Heading> */}
      <CustomBreadcrumb items={breadcrumbItems}></CustomBreadcrumb>
      <Grid templateColumns="1fr 300px" gap={6}>
        <GridItem>
          <Flex
            align="center"
            justify="center"
            position="relative"
            boxShadow="lg"
            borderRadius="md"
            bg="white"
            p={4}
          >
            <svg ref={svgRef}></svg>
            <Flex
              position="absolute"
              top={4}
              right={4}
              direction="column"
              alignItems="center"
            >
              <IconButton
                aria-label="Zoom In"
                icon={<FaSearchPlus />}
                onClick={handleZoomIn}
                mb={2}
                colorScheme="purple"
              />
              <IconButton
                aria-label="Zoom Out"
                icon={<FaSearchMinus />}
                onClick={handleZoomOut}
                colorScheme="purple"
              />
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          <Box
            bg="white"
            p={3}
            boxShadow="lg"
            borderRadius="md"
            height="100%"
            width="100%"
          >
            <Heading size="md" color="purple.400" mb={4}>
              Node Properties
            </Heading>
            <Tabs size="sm" variant="enclosed">
              <TabList
                overflowX="auto"
                whiteSpace="nowrap"
                aria-orientation="vertical"
              >
                <Tab fontSize="sm">Content</Tab>
                <Tab fontSize="sm">Flow Diagram</Tab>
                <Tab fontSize="sm">Unit Tests</Tab>
                <Tab fontSize="sm">Description</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Text>
                    <strong>Label:</strong>{" "}
                    {selectedNode ? selectedNode?.labels?.join(", ") : "---"}
                  </Text>
                  <Text>
                    <strong>Name:</strong>{" "}
                    {selectedNode ? selectedNode?.properties?.name : "---"}
                  </Text>
                  <Text>
                    <strong>Content:</strong>
                  </Text>
                  <Box
                    as="pre"
                    bg="gray.50"
                    p={1}
                    borderRadius="md"
                    whiteSpace="pre"
                    fontFamily="monospace"
                    style={{ overflowX: "auto" }}
                    maxH="400px" // Max height to prevent vertical stretching
                    maxW="275px"
                  >
                    {selectedNode
                      ? selectedNode?.properties?.content
                      : "No content available"}
                  </Box>
                </TabPanel>

                <TabPanel>
                  {selectedNode &&
                  selectedNode?.properties?.mermaid_diagram !== undefined &&
                  selectedNode?.properties?.mermaid_diagram ? (
                    <>
                      <Text>
                        <strong>Flow Diagram:</strong>
                      </Text>
                      <MermaidDiagram
                        query={selectedNode?.properties?.mermaid_diagram}
                      />
                    </>
                  ) : (
                    <Text>No flow diagram available</Text>
                  )}
                </TabPanel>

                <TabPanel>
                  <Text>
                    <strong>Unit Tests:</strong>
                  </Text>
                  <Box
                    as="pre"
                    bg="gray.50"
                    p={1}
                    borderRadius="md"
                    whiteSpace="pre"
                    fontFamily="monospace"
                    style={{ overflowX: "auto" }}
                    maxH="400px" // Max height to prevent vertical stretching
                    maxW="275px"
                  >
                    {selectedNode
                      ? selectedNode?.properties?.unit_tests
                      : "No unit tests available"}
                  </Box>
                </TabPanel>

                <TabPanel>
                  <Text>
                    <strong>Description:</strong>
                  </Text>
                  <Box
                    as="pre"
                    bg="gray.50"
                    p={1}
                    borderRadius="md"
                    whiteSpace="pre"
                    fontFamily="monospace"
                    style={{ overflowX: "auto" }}
                    maxH="400px" // Max height to prevent vertical stretching
                    maxW="275px"
                  >
                    {selectedNode
                      ? selectedNode?.properties?.description
                      : "No description available"}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default D3Graph;
