import createRoutes from '../../utils/utilities';
import D3Graph from './VisualGraph';


export const visualizeGraphRoutes = {
    visualizeGraph: {
        name: "Visualize Repo",
        path: "/visualize/:repoId",
        component: D3Graph,
        private: true
    },
};

const VisualizeGraphRoutes = createRoutes({ routes: visualizeGraphRoutes });
export default VisualizeGraphRoutes;