// HomePage.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { FexForm } from "../Form.js";
import Navbar from "../common/NavBar.js";

import "../../App.css";

function HomePage() {
  const location = useLocation();

  const defaultState = {
    quipLinks: [""],
    reviewComments: "",
    mermaidTexts: [],
  };

  const [inputValue, setInputValue] = useState("");
  const [reviewInputValue, setReviewInputValue] = useState("");
  const [textAreas, setTextAreas] = useState([{ id: uuidv4(), value: "" }]);
  const [messages, setMessages] = useState([]);
  const [mermaidText, setMermaidText] = useState("");
  const [mermaidTexts, setMermaidTexts] = useState([]);
  const [reviewComments, setReviewComments] = useState([]);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const handleNavigateToAPIList = () => {
    navigate("/api-list", {
      state: {
        reviewComments: reviewComments,
        mermaidTexts: mermaidTexts,
        quipLinks: textAreas,
      },
    });
  };

  const handleInputChange = (id, event) => {
    const newValue = event.target.value;
    setTextAreas((currentTextAreas) =>
      currentTextAreas.map((textArea) =>
        textArea.id === id ? { ...textArea, value: newValue } : textArea
      )
    );
  };

  const addTextArea = () => {
    if (textAreas.length < 5) {
      setTextAreas((currentTextAreas) => [
        ...currentTextAreas,
        { id: uuidv4(), value: "" },
      ]);
    } else {
      alert("Maximum of 5 text areas can be added.");
    }
  };

  const removeTextArea = (id) => {
    setTextAreas((currentTextAreas) =>
      currentTextAreas.filter((textArea) => textArea.id !== id)
    );
  };

  useEffect(() => {
    setProcessing(false);
    if (location.state) {
      setTextAreas(location.state.quipLinks || []);
      setReviewComments(location.state.reviewComments || "");
      setMermaidTexts(location.state.mermaidTexts || []);
    } else {
      setTextAreas(defaultState.quipLinks);
      setReviewComments(defaultState.reviewComments);
      setMermaidTexts(defaultState.mermaidTexts);
    }
  }, []);

  const sendPostRequest = async () => {
    setProcessing(true);
    try {
      const response = await fetch("http://localhost:8081/v1.0/devas/chat/mermaid_diagram",{
		// const response = await fetch("https://api.fexz0.de/service/public/v1.0/devas/chat/mermaid_diagram", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ quip_links: textAreas }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Post request successful:", data);
      setMermaidTexts(data);
    } catch (error) {
      console.error("Error sending post request:", error);
    }
    setProcessing(false);
  };

  const sendReviewPostRequest = async () => {
    try {
      setProcessing(true);
    //   const response = await fetch("https://api.fexz0.de/service/public/v1.0/devas/chat/mermaid_diagram", {
	  const response = await fetch("http://localhost:8081/v1.0/devas/chat/mermaid_diagram", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            quip_link: inputValue,
            review_comments: reviewInputValue,
          }),
        }
      );
      setReviewComments((prevMessages) => [...prevMessages, reviewInputValue]);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Post request successful:", data);
      setMermaidTexts(data);
      setProcessing(false);
    } catch (error) {
      console.error("Error sending post request:", error);
    }
  };

  const handleApproveClick = () => {
    navigate("/devas", {
      state: {
        quip_link: inputValue,
        reviewComments: reviewComments,
        mermaidTexts: mermaidTexts,
        quipLinks: textAreas,
      },
    });
  };

  return (
    <div
      className="App flex-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <Navbar />
      <div
        id="rightDiv"
        style={{
          flex: 3,
          marginLeft: "40vh", // Adjust main content to the width of the navbar
          minHeight: "100vh",
        }}
      >
        <FexForm />
      </div>
    </div>
  );
}

export default HomePage;
