import createRoutes from '../../utils/utilities';
import SearchEngine from './SearchEngine';

export const ragRoutes = {
    searchEngine: {
        name: "Search Engine",
        path: "/quipwhiz",
        component: SearchEngine,
        private: true
    },
};

const RagRoutes = createRoutes({ routes: ragRoutes });
export default RagRoutes;