import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseNejiUrl } from '../../utils/constants';

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');

      if (code) {
        try {
          const response = await fetch(`${baseNejiUrl}authentication/register/complete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: code }),
          });

          if (response.ok) {
            // Redirect to a success page or show a success message
            navigate('/verification-success');
          } else {
            // Handle error response
            const errorData = await response.json();
            console.error('Verification failed:', errorData);
          }
        } catch (error) {
          console.error('An error occurred during verification:', error);
        }
      }
    };

    verifyEmail();
  }, []);
};

export default EmailVerification;
