import createRoutes from '../../utils/utilities';
import Repositories from './Repositories';
// import ConfigurationPage from './RepositoryConfig';
import D3Graph from './VisualGraph';

export const repoRoutes = {
    repositories: {
        name: "Repositories",
        path: "/repos",
        component: Repositories,
        private: true
    },
    // repositoryConfiguration: {
    //     name: "Configuration",
    //     path: "/repo_configuration/:repoId",
    //     component: ConfigurationPage,
    //     private: true
    // },
    visualizeGraph: {
        name: "Visualize Repo",
        path: "/repoVisualize/:repoId",
        component: D3Graph,
        private: true
    },
};

const RepoRoutes = createRoutes({ routes: repoRoutes });
export default RepoRoutes;