import React, { useEffect } from 'react';
import {
  Box, Flex, Text, Stack, Heading, Image, useColorModeValue, Icon, Link, useColorMode
} from '@chakra-ui/react';
import { FaCode, FaTools, FaDraftingCompass ,FaFileAlt , FaProjectDiagram} from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import ProfileMenu from './common/MenuProfile';
const LandingPage = () => {
  const { setColorMode } = useColorMode();
  setColorMode("light");
  const boxBg = useColorModeValue('white', 'white');
  const mainBg = useColorModeValue('gray.50', 'gray.50');
  const textColor = useColorModeValue('gray.800', 'gray.800');
  const descriptionColor = useColorModeValue('gray.600', 'gray.400');
  const cardBg = useColorModeValue('white', 'white');
  const cardHoverBg = useColorModeValue('gray.100', 'gray.100');
  const boxHeight = "300px"; // Fixed height for uniformity
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/org-selection');
  }, [navigate]);

  const handleOrgSelection = (e, pagePath) => {
    e.preventDefault();
    navigate('/org-selection', { state: { redirectTo: pagePath } });
  };

  return (
    <Box bg={mainBg} minH="100vh">
      <Flex justifyContent="space-between" alignItems="center" p={4} bg={boxBg} boxShadow="sm">
        <Flex alignItems="center">
          <svg width="50" height="50" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_26659)">
            <path d="M5.14498 0.59082H1.74123H1.73073V0.60832L0.00523376 3.64282L1.73073 6.59332L3.46148 3.55007H11.522L13.9947 0.59082H5.14498Z" fill="#A380FF"/>
            <path d="M0.909912 10.459L2.63541 13.4095L4.36616 10.3662L5.81866 7.81472H9.00366L10.7992 4.85547H4.10366L0.909912 10.459Z" fill="#A380FF"/>
            </g>
            <defs>
            <clipPath id="clip0_1073_26659">
            <rect width="13.9895" height="12.8188" fill="white" transform="translate(0.00523376 0.59082)"/>
            </clipPath>
            </defs>
          </svg>
          {/* <Image src="https://res.cloudinary.com/ddf4fufyx/image/upload/v1714634024/ai-logo-in_njazhv.png" alt="Fynix Logo" boxSize="50px" mr={4} /> */}
          <Heading sx={{
            background: '#A380FF',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
          }} size="lg">FyniX</Heading>
        </Flex>
        <ProfileMenu hideWorkspace={true} hideConfig={true} />
      </Flex>
      <Box p={10}>
        <Flex justifyContent="center" mb={8}>
          <Heading color={textColor}>Our Products</Heading>
        </Flex>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={8} align="center" justify="center">
          <Link onClick={(e) => handleOrgSelection(e, '/dashboard')} _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bgGradient:"linear(to-b, blue.200, pink.200)", transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="lg"
              bgGradient="linear(to-b, blue.100, pink.100)"
            >
              <Icon as={FaCode} boxSize={10} mb={4} mt={10} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>AI Code Quality Agent</Heading>
              <Text color={descriptionColor}>A powerful AI-based tool to automate and streamline code reviews for pull requests.</Text>
            </Box>
          </Link>
          {/* <Link onClick={(e) => handleOrgSelection(e, '/repos')} _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bgGradient:"linear(to-b, blue.200, pink.200)", transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="lg"
              bgGradient="linear(to-b, blue.100, pink.100)"
            >
              <Icon as={FaCode} boxSize={10} mb={4} mt={10} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>Repositories</Heading>
              <Text color={descriptionColor}>A visual representation of your Code and the control flows through the entire codebase.</Text>
            </Box>
          </Link> */}
          {/* <Link href="/my-brds" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bg: cardHoverBg, transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="md"
            >
              <Icon as={FaDraftingCompass} boxSize={10} mb={4} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>TDD Builder</Heading>
              <Text color={descriptionColor}>Build and manage your own design docs with AI.</Text>
            </Box>
          </Link> */}
          {/* <Link href="/doccreator/projects" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bgGradient:"linear(to-b, blue.200, pink.200)", transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="lg"
              bgGradient="linear(to-b, blue.100, pink.100)"
            >
              <Icon as={FaFileAlt} boxSize={10} mb={4} mt={10} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>AI Document Creator</Heading>
              <Text color={descriptionColor}>An AI Chief Product Officer that crafts and refines high-quality Documents (PRD/BRD).</Text>
            </Box>
          </Link> */}
          {/* <Link href="diagrams/projects" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bgGradient:"linear(to-b, blue.200, pink.200)", transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="lg"
              bgGradient="linear(to-b, blue.100, pink.100)"
            >
              <Icon as={FaProjectDiagram} boxSize={10} mb={4} mt={10} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>AI Diagram Creator</Heading>
              <Text color={descriptionColor}>A tool that automatically generates diagrams to visualize the interaction between different system components.</Text>
            </Box>
          </Link> */}
          {/* <Link href="/devas/projects" _hover={{ textDecoration: 'none' }} flex="1" textAlign="center">
            <Box
              bg={cardBg}
              p={5}
              borderRadius="lg"
              _hover={{ bg: cardHoverBg, transform: 'scale(1.05)', transition: 'all 0.3s ease-in-out' }}
              textAlign="center"
              transition="all 0.3s ease-in-out"
              height={boxHeight}
              boxShadow="md"
            >
              <Icon as={FaTools} boxSize={10} mb={4} color="purple.500" />
              <Heading size="md" mb={3} color={textColor}>Devas</Heading>
              <Text color={descriptionColor}>Generate a full-fledged software using AI-based chain of thoughts.</Text>
            </Box>
          </Link> */}
        </Stack>
      </Box>
    </Box>
  );
};
export default LandingPage;