import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Text, Center, VStack, chakra, useColorMode } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { keyframes } from '@emotion/react';

const zoomInOut = keyframes`
  0% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedCheckCircleIcon = chakra(CheckCircleIcon, {
  shouldForwardProp: (prop) => true,
});

const VerificationSuccess = () => {
  const navigate = useNavigate();
  const {setColorMode} = useColorMode();
  setColorMode("light");

  const handleLoginRedirect = () => {
    navigate('/');
  };

  return (
    <Center h="100vh" bg="gray.100" p={4}>
      <Box
        p={10}
        maxWidth="600px"
        borderWidth={1}
        borderRadius={12}
        boxShadow="xl"
        bg="white"
      >
        <VStack spacing={6}>
          <AnimatedCheckCircleIcon 
            w={20} 
            h={20} 
            color="green.500" 
            animation={`${zoomInOut} 1s`}
          />
          <Text fontSize="2xl" fontWeight="bold">
            Email Verification Successful
          </Text>
          <Text fontSize="lg" color="gray.600" textAlign="center">
            You can now log in with your account.
          </Text>
          <Button colorScheme="purple" onClick={handleLoginRedirect}>
            Go to Login
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

export default VerificationSuccess;
