import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { defaultScreenshot } from "../../utils/screenshot";
import { baseDevasUrl, baseDevasWebsocketUrl } from "../../utils/constants"
import {
    Box,
    Button,
    Input,
    Text,
    VStack,
    Flex,
    Heading,
    Divider,
    useColorModeValue,
    useColorMode,
    IconButton,
    Spacer,
    Image,
    ListItem,
    ListIcon,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import ChatComponent from "../common/ChatComponent";
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.7);
  }
`;

function DevasPage(props) {
    let { projectId } = useParams();
    const location = useLocation();
    const { gitUrl, quipLinks } = location.state || { gitUrl: '', quipLinks: [] };
    const { brdData } = location.state || { brdData: '' };
    const { toggleColorMode, setColorMode, colorMode } = useColorMode();
    let brdSentOnce = false;
    const [socket, setSocket] = useState(null);
    const [userMessage, setUserMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [terminalHistory, setTerminalHistory] = useState([]);
    const [currentCommand, setCurrentCommand] = useState('');
    const [url, setUrl] = useState(null);
    const [screenshot, setScreenshot] = useState(defaultScreenshot);
    const [pastThreads, setPastThreads] = useState([]); // State to store past threads fetched from API
    const [chatConfig, setchatConfig] = useState('');


    const bgColor = useColorModeValue("gray.50", "gray.800");
    const cardBgColor = useColorModeValue("white", "gray.700");
    const planMessageBgColor = useColorModeValue('blue.100', 'blue.900');
    const userMessageBgColor = useColorModeValue('green.100', 'green.800');
    const assistantMessageBgColor = useColorModeValue('blue.100', 'blue.800');
    const getThreadsApi = `${baseDevasUrl}projects/${projectId}/threads`;

    let tempchatConfig = {
        communicationURL: brdData.length > 0 ? `${baseDevasWebsocketUrl}devas/chat/brd` : '',
        communicationMethod: 'websocket',
        getCommunicationURL:(thread_id) => `${baseDevasWebsocketUrl}devas/chat/${thread_id}`,
        isCrateNewSocket: false,
        socket: socket,
        colors: {
            background: {
                light: "gray.50",
                dark: "gray.800"
            },
            userMessageBackground: {
                light: "green.100",
                dark: "green.800"
            },
            assistantMessageBackground: {
                light: "blue.100",
                dark: "blue.800"
            },
            planMessageBackground: {
                light: "blue.100",
                dark: "blue.900"
            },
            cardBackground: {
                light: "white",
                dark: "gray.700"
            }
        }
    };
    

    const outputParser = (output_data) => {
        // Example: Add a prefix to the output message
        const data = JSON.parse(output_data);
        if (data.window === 'terminal') {
            updateTerminal(data.message);
        } else if (data.window === 'browser') {
            updateBrowser(data);
        } else if (data.type === 'plan') {
            const steps = Object.entries(data.message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            return { role: "assistant", content: steps, type: 'plan' }
        } else {
            return { role: "assistant", content: data.message, type: 'text' }
        }
    };

    const renderMessages = (chatMessages) => {
        return chatMessages.map((msg, index) => {
            if (msg.type === 'plan') {
                return (
                    <Box key={index} bg={planMessageBgColor} p={4} borderRadius="md" alignSelf="flex-start">
                        {msg.content.split("\n").map((line, idx) => (
                            <Text key={idx} fontSize="md" mb={2}>{line}</Text>
                        ))}
                    </Box>
                );
            } else {
                if (msg && msg.content){
                    // Splitting the message content by newline characters and rendering each part as a separate line
                    const messageLines = msg.content.split("\n").map((line, idx, arr) => (
                        <Text key={idx} as="span">
                            {line}
                            {idx < arr.length - 1 && <br />} {/* Add a line break for all but the last line */}
                        </Text>
                    ));
    
                    return (
                        <Box key={index} bg={msg.role === "user" ? userMessageBgColor : assistantMessageBgColor} p={3} borderRadius="md" alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}>
                            {messageLines}
                        </Box>
                    );
                }
            }
        });
    };
    
    
    const fetchPastThreads = async () => {
        try {
            const response = await fetch(getThreadsApi);
            if (!response.ok) {
                throw new Error('Failed to fetch past threads');
            }
            const response_data = await response.json();
            if (response_data.data.length > 0){
                response_data.data.sort((x, y) => y.id - x.id);
                setPastThreads(response_data.data);
                loadThreadMessages(response_data.data[0].id);
                tempchatConfig.communicationURL = `${baseDevasWebsocketUrl}devas/chat/${response_data.data[0].id}`
                setchatConfig(tempchatConfig);

            }
            
        } catch (error) {
            console.error('Error fetching past threads:', error);
        }
    };

    const loadThreadMessages = (thread_id) => {
        setTerminalHistory([]);
        setChatMessages([]);
        fetch(`${baseDevasUrl}threads/${thread_id}/messages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                // conso
                // console.log(response);
                // console.log(response.data);
                if (response.success && response.data.length > 0) {
                    const messages = response.data
                    // console.log('messages >>', messages);
                    for (const message of messages) {
                        // console.log("message >>", message);
                        // console.log("chatMessages >>", chatMessages);
                        if (message.is_json) {
                            const data = JSON.parse(message.content);
                            if (data.window === 'terminal') {
                                updateTerminal(data.message);
                            } else if (data.window === 'browser') {
                                updateBrowser(data);
                            } else if (data.type === 'plan') {
                                receiveMessage(data.message, 'plan');
                            } else {
                                receiveMessage(data.message, 'text');
                            }
                        } else {
                            setChatMessages((previousChat) => [...previousChat, message]);
                        }
                    }
                }
                // if (response.success && Object.keys(response.data).length > 0) {
                //     const messages = response.data.messages
                //     console.log('messages >>', messages);
                //     for (const message of messages) {
                //         console.log("message >>", message);
                //         if (message.is_json) {
                //             const data = JSON.parse(message.content);
                //             setIsTyping(false);
                //             if (data.window === 'terminal') {
                //                 updateTerminal(data.message);
                //             } else if (data.window === 'browser') {
                //                 updateBrowser(data);
                //             } else if (data.type === 'plan') {
                //                 receiveMessage(data.message, 'plan');
                //             } else {
                //                 receiveMessage(data.message, 'text');
                //             }
                //         } else {
                //             setChatMessages((prev) => [...prev, message]);
                //         }
                //     }
                // }
                // Handle the data from the response here
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };

    const createChatThread = async (name) => {
        setChatMessages([]);
        const response = await fetch(`${baseDevasUrl}threads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'GET, POST',
                'Access-Control-Allow-Headers': 'Content-Type',
            },
            body: JSON.stringify({
                    "name": name,
                    "requested_by": localStorage.getItem("email"),
                    "alternate_id":`${projectId}`
            }),
        });

        if (response.ok) {
            const response_data= await response.json()
            await fetchPastThreads();
            const thread_id = response_data.data.thread_id;
            return thread_id;
        }
    };


    useEffect(() => {
        setColorMode("dark");
        setchatConfig(tempchatConfig);
        // createProject();
        fetchPastThreads();
    }, []);


    // browser
    const handleInputChange = (event) => {
        setUrl(event.target.value);
    };

    const updateBrowser = (data) => {
        if (data.type === "link") {
            setUrl(data.message.link);
        } else if (data.type === "image") {
            setScreenshot(data.message.image);
        }
    }

    const receiveMessage = (message, type) => {
        if (type === 'plan') {
            const steps = Object.entries(message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            setChatMessages((prev) => [...prev, { role: "assistant", content: steps, type: 'plan' }]);
        } else {
            setChatMessages((prev) => [...prev, { role: "assistant", content: message, type: 'text' }]);
        }
    };

    const updateTerminal = (data) => {
        setTerminalHistory((prevHistory) => {
            if (prevHistory.length > 0) {
                const lastEntry = prevHistory[prevHistory.length - 1];
                const isSameCommand = lastEntry.command === data.command;

                if (isSameCommand) {
                    // Append new output to the latest command output
                    const updatedHistory = [...prevHistory];
                    updatedHistory[updatedHistory.length - 1].output += "\n" + data.output; // Append new output
                    return updatedHistory;
                }
            }
            // New command or not recent, add to history
            return [
                ...prevHistory,
                { command: data.command, output: data.output }
            ];
        });
        setCurrentCommand('');
    };

    return (
        <Flex h="100vh" p={4} bg={bgColor}>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} mr={4}>
                <ChatComponent
                    chatConfig={chatConfig}
                    outputParser={outputParser}
                    chatMessages={chatMessages}
                    setchatConfig={setchatConfig}
                    setChatMessages={setChatMessages}
                    renderMessages={renderMessages}
                    loadThreadMessages={(thread_id) => loadThreadMessages(thread_id)}
                    createChatThread={(name) => createChatThread(name)}
                    pastThreads={pastThreads}
                    setPastThreads={setPastThreads}
                />
            </Flex>
            <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor}>
                <Box mb="4" h="50%">
                    <Heading fontSize="xl">Terminal</Heading>
                    <Divider my="4" />
                    <Box className="terminal" h="calc(100% - 60px)" p={2} overflowX="auto">
                        {terminalHistory.map((cmd, index) => (
                            <Box key={index} className="terminal-command">
                                <Text className="command-prompt">devas@MacBook-Pro-210 % <Text className="terminal-input">{cmd.command}</Text></Text>
                                <Text className="terminal-output" size={10}><pre style={{ fontSize: '13px' }}>{cmd.output}</pre></Text>
                            </Box>
                        ))}
                        <Box className="terminal-command">
                            <Text className="command-prompt">devas@MacBook-Pro-210 % <Text className="terminal-input">{currentCommand}</Text><Text className="blinking-cursor"></Text></Text>
                        </Box>
                    </Box>
                </Box>
                <Box h="50%">
                    <Heading fontSize="xl">Browser Window</Heading>
                    <Divider my="4" />
                    <Box className="browser" h="calc(100% - 60px)" p={2}>
                        <input
                            type="text"
                            value={url}
                            disabled={true}
                            onChange={handleInputChange}
                            placeholder="devas://newtab"
                            style={{
                                width: '100%',
                                padding: '10px',
                                marginBottom: '8px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                boxShadow: '0 2px 6px rgba(0,0,0,0.1)'
                            }}
                        />
                        {/* Scrollable container specifically for the image */}
                        <Box overflowY="auto" maxH="85%">
                            <Image
                                className="browser-img"
                                src={screenshot}
                                alt="Browser snapshot"
                                w="100%"
                                objectFit="contain"
                                loading="lazy"
                            />
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default DevasPage;
