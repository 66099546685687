import React, { useState } from 'react';
import {
  Box,
  VStack,
  HStack,
  IconButton,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  Spacer
} from '@chakra-ui/react';
import { FiHome, FiDatabase, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons'; // This icon can be replaced with any 3-dot icon

const PrSideBar = ({ currentPage }) => {
  const sidebarBg = useColorModeValue('gray.50', 'gray.800');
  const navigate = useNavigate();
  const menuItems = [
    { icon: FiHome, label: 'Dashboard', link: '/dashboard' },
    { icon: FiDatabase, label: 'Repositories', link: '/repositories' },
  ];

  const handleNavigation = (link) => {
    navigate(link);
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
  const hoverBgColor = useColorModeValue('purple.100', 'purple.100');

  return (
    <Box w={{ base: "100%", md: "300px" }} bg={sidebarBg} p="4" overflowY="auto" boxShadow="lg">
      {isMobile ? (
        <Menu>
          {/* <MenuButton as={IconButton} icon={<HamburgerIcon />} variant="outline" aria-label="Options" />
          <MenuList>
            {menuItems.map((item) => (
              <MenuItem key={item.label} icon={< item.icon />} onClick={() => handleNavigation(item.link)}>
                {item.label}
              </MenuItem>
            ))}
            <MenuItem icon={<FiArrowLeft />} onClick={() => navigate('/products')}>
              Back to Home
            </MenuItem>
          </MenuList> */}
        </Menu>
      ) : (
        <VStack align="start" spacing="2" height="full">
          {menuItems.map((item) => (
            <HStack
              key={item.label}
              w="100%"
              spacing="2"
              alignItems="center"
              bg={currentPage === item.link ? 'purple.100' : 'transparent'}
              boxShadow={currentPage === item.link ? 'md' : ''}
              borderRadius="md"
              p="1"
              onClick={() => handleNavigation(item.link)}
              cursor="pointer"
            >
              <IconButton
                icon={<item.icon />}
                variant="ghost"
                size="lg"
                isRound
                aria-label={item.label}
              />
              <Text>{item.label}</Text>
            </HStack>
          ))}
          <Spacer />
          {/* <HStack
            w="100%"
            spacing="2"
            alignItems="center"
            bg="transparent"
            borderRadius="md"
            p="1"
            onClick={() => navigate('/products')}
            cursor="pointer"
            _hover={{ bg: hoverBgColor }}
          >
            <IconButton
              icon={<FiArrowLeft />}
              variant="ghost"
              size="lg"
              isRound
              aria-label="Back to Home"
            />
            <Text>Back to Home</Text>
          </HStack> */}
        </VStack>
      )}
    </Box>
  );

};

export default PrSideBar;
