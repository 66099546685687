import React, { useEffect, useState } from 'react';
import {
    Box, Button, Heading, Stack, Table, Tbody, Td, Th, Thead, Tr,
    useToast, Input, FormControl, FormLabel, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Text, Flex,
    useColorMode, IconButton, HStack
} from '@chakra-ui/react';
import { FaPlus, FaEdit, FaTrash, FaEye } from 'react-icons/fa';
import { baseDevasUrl, baseUrl } from '../../utils/constants';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
const productId = 1

const DevasProject = () => {
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
const productId = 1
    const [newProject, setNewProject] = useState({ name: '',product_id: productId, org_id: 0, repo_url: '', reference_repo_url: '', created_by: localStorage.getItem("email") });
    const [editProject, setEditProject] = useState(null);
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
    const { setColorMode } = useColorMode();
    setColorMode("light");

    useEffect(() => {
        fetchProjects();
    }, [currentPage]);

    const fetchProjects = async () => {
        try {
            const response = await fetch(`${baseDevasUrl}projects?user_email=${localStorage.getItem("email")}&product_id=${productId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            setProjects(data.data.projects);
            setTotalPages(Math.ceil(data.data.projects.length / 8)); // Assuming 5 projects per page
        } catch (error) {
            console.log("Error while fetching projects: ", error);
        }
    };

    const createProject = async () => {
        try {
            const response = await fetch(`${baseDevasUrl}projects`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newProject)
            });
            const data = await response.json();
            if (data.success) {
                toast({ title: data.data.message, status: "success" });
                fetchProjects();
                setNewProject({ name: '', product_id: productId , org_id: 0, repo_url: '', reference_repo_url: '', created_by: '' });
                onClose();
            } else {
                console.log("Error while creating project");
            }
        } catch (error) {
            console.log("Error while creating project");
        }
    };


    const updateProject = async () => {
        try {
            const response = await fetch(`${baseDevasUrl}projects/${editProject.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: editProject.name })
            });
            const data = await response.json();
            if (data.success) {
                toast({ title: "Project updated successfully!", status: "success" });
                fetchProjects();
                setEditProject(null);
                onEditClose();
            } else {
                console.log("Error while updating project");
            }
        } catch (error) {
            console.log("Error while updating project");
        }
    };

    const deleteProject = async (id) => {
        try {
            const response = await fetch(`${baseDevasUrl}projects/${id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            if (data.success) {
                toast({ title: "Project deleted successfully!", status: "success" });
                fetchProjects();
            } else {
                console.log("Error while deleting project");
            }
        } catch (error) {
            console.log("Error while deleting project");
        }
    };

    return (
        <Box p={5}>
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Heading>Devas Projects</Heading>
                <Button onClick={onOpen} leftIcon={<FaPlus />} colorScheme="purple">Add Project</Button>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create New Project</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={3}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input value={newProject.name} focusBorderColor="purple.400" onChange={(e) => setNewProject({ ...newProject, name: e.target.value })} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Repository URL</FormLabel>
                                <Input value={newProject.repo_url} focusBorderColor="purple.400" onChange={(e) => setNewProject({ ...newProject, repo_url: e.target.value })} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Reference Repository URL</FormLabel>
                                <Input value={newProject.reference_repo_url} focusBorderColor="purple.400" onChange={(e) => setNewProject({ ...newProject, reference_repo_url: e.target.value })} />
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createProject} colorScheme="purple">Create Project</Button>
                        <Button onClick={onClose} ml={3}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isEditOpen} onClose={onEditClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Project</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input value={editProject?.name || ''} focusBorderColor="purple.400" onChange={(e) => setEditProject({ ...editProject, name: e.target.value })} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={updateProject} colorScheme="purple">Update Project</Button>
                        <Button onClick={onEditClose} ml={3}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th>Repository URL</Th>
                            <Th>Reference Repository URL</Th>
                            <Th>Created By</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {projects.slice((currentPage - 1) * 8, currentPage * 8).map((project, index) => (
                            <Tr key={index}>
                                <Td>{project.name}</Td>
                                <Td>{project.repo_url}</Td>
                                <Td>{project.reference_repo_url}</Td>
                                <Td>{project.created_by}</Td>
                                <Td>
                                    <Button leftIcon={<FaEye />} size="sm" variant={"outline"} colorScheme="blue" mr={2} onClick={() => window.location.href=`/devas/${project.id}`}>View</Button>
                                    <Button leftIcon={<FaEdit />} size="sm" variant={"outline"} colorScheme="green" mr={2} onClick={() => { setEditProject(project); onEditOpen(); }}>Edit</Button>
                                    <Button leftIcon={<FaTrash />} size="sm" variant={"outline"} colorScheme="red" onClick={() => deleteProject(project.id)}>Delete</Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <HStack mt="4" justifyContent="center">
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        aria-label="Previous Page"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                    />
                    <Text>Page {currentPage} of {totalPages}</Text>
                    <IconButton
                        icon={<ChevronRightIcon />}
                        aria-label="Next Page"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                    />
                </HStack>
            </Box>
        </Box>
    );
};

export default DevasProject;
