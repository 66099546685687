import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
  Text,
  Divider,
  useColorMode,
  VStack,
  Icon,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton
} from '@chakra-ui/react';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import Select from 'react-select';
import { getSessionDetails } from '../../utils/utilities';
import { baseHiruzenAdminUrl, baseHiruzenPlatformUrl, baseNejiUrl, baseReviewPilotUrl } from '../../utils/constants';
import { useWorkspace } from '../../utils/workspaceContext';
import { useNavigate, useLocation } from "react-router-dom";
import ProfileMenu from './MenuProfile';

const OrganizationPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const { currentWorkspace, setCurrentWorkspace } = useWorkspace();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [organizationName, setOrganizationName] = useState('');
  const [azureBaseUrl, setAzureBaseUrl] = useState('');
  const [personalAccessToken, setPersonalAccessToken] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { setColorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  setColorMode('light');

  const { redirectTo } = location.state || { redirectTo: '/dashboard' }; 

  useEffect(() => {
    // Fetch organizations from session workspaces
    setOrganizations(getSessionDetails().session.passport.user.workspace);
  }, []);

  useEffect(() => {
    // Fetch organizations from session workspaces
    // setOrganizations(getSessionDetails().session.passport.user.workspace);
    if (organizations.length === 1) {
      handleWorkspaceSwitch(organizations[0].orgId, organizations[0].urmId, true);
    }

  }, [organizations]);

  const handleChange = (selectedOption) => {
    setSelectedOrganization(selectedOption);
    setCurrentWorkspace({orgId: selectedOption.value, urmId: selectedOption.urmId});
  };

  const handleWorkspaceSwitch = async (workspaceId, urmId, redirect=false) => {
    const endpoint = `authentication/login/workspace/${urmId}`;
    const url = `${baseNejiUrl}${endpoint}`;
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      });

      if (response.ok) {
        setCurrentWorkspace({orgId: workspaceId, urmId: urmId});
        const resp = await response.json();
        localStorage.setItem('token', btoa(JSON.stringify(resp)));
        if (redirect) {
          navigate(redirectTo);
        }
      } else {
        throw new Error('Failed to switch workspace');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateOrganization = async () => {
    if (!organizationName) {
      toast({
        title: 'Please provide all the required fields',
        status: 'warning',
        duration: 1000,
        isClosable: true,
        position: 'top'
      });
      return;
    }

    const newOrganization = {
      name: organizationName,
      azureBaseUrl,
      personalAccessToken,
    };

    try {
      const response = await fetch(`${baseHiruzenAdminUrl}/org`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: organizationName }),
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setOrganizations([...organizations, data.result]);
        setOrganizationName('');
        setAzureBaseUrl('');
        setPersonalAccessToken('');
        onClose();
        const currentUserId = parseInt(getSessionDetails().session.passport.user._id);
        const orgId = data.result._id;
        await fetch(`${baseHiruzenPlatformUrl}role/update-user-mappings?orgId=${orgId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: [
              {
                userId: currentUserId,
                roleSlug: 'org-owner',
              },
            ],
          }),
          credentials: 'include',
        });

        const sessionResponse = await fetch(`${baseNejiUrl}session`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        const sessionData = await sessionResponse.json();
        if (sessionResponse.ok) {
          const encodedSession = btoa(JSON.stringify(sessionData));
          localStorage.setItem('token', encodedSession);
          setOrganizations(getSessionDetails().session.passport.user.workspace);
          toast({
                  title: 'Organisation Created!',
                  description: "Organisation created successfully with provided details.",
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right'
                });
        }

        // try {
        //   const response = await fetch(`${baseReviewPilotUrl}org_details`, {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //       org_id: orgId,
        //       org_name: organizationName,
        //     }),
        //   });

        //   const data = await response.json();
        //   if (!response.ok) {
        //     console.error('Error sending organization details:', data);
        //   } else {
        //     toast({
        //       title: 'Organisation Created!',
        //       description: "Organisation created successfully with provided details.",
        //       status: 'success',
        //       duration: 5000,
        //       isClosable: true,
        //       position: 'top-right'
        //     });
        //   }
        // } catch (error) {
        //   console.error('Error sending organization details:', error);
        // }
      } else {
        console.error('Error creating organization:', data);
        if (response.status === 400) {
          toast({
            title: 'Bad Request',
            description: data.message,
            status: 'warning',
            duration: 5000,
            isClosable: true,
            position: 'top-right'
          });
          return;
        } else if (response.status === 500) {
          toast({
            title: 'Server Error',
            description: 'Oops! Something went wrong on our end. Please try again later.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right'
          });
        }
      }
    }
    catch (error) {
      console.error('Error creating organization:', error);
      toast({
        title: 'Bad Request',
        description: 'Unknown Error Occured',
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };

  const handleProceed = () => {
    if (!selectedOrganization) {
      toast({
        title: 'Selection Required',
        description: "Please select an organization before proceeding.",
        status: 'warning',
        duration: 1000,
        isClosable: true,
        position: 'top-right'
      });
      return;
    }
    handleWorkspaceSwitch(selectedOrganization.orgId, selectedOrganization.urmId, true);
    // navigate(redirectTo);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#9F7AEA',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#9F7AEA',
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Box position="absolute" top="0" right="0" p={4}>
        <ProfileMenu hideWorkspace={true} hideConfig={true} />
      </Box>
      <Box p={8} maxWidth="500px" width="100%" borderWidth="1px" borderRadius="lg" boxShadow="lg" bg="white">
        <Heading mb={4} textAlign="center">Organizations</Heading>
        <VStack spacing={4}>
          <Box width="100%">
            <FormControl>
              <Select
                placeholder="Select organization"
                value={selectedOrganization}
                onChange={handleChange}
                options={organizations.map(org => ({ value: org.orgId, label: org.orgName, urmId: org.urmId }))}
                styles={customStyles}
                isDisabled={organizations.length === 0}
              />
            </FormControl>
          </Box>

          <Divider />

          <Button
            onClick={handleProceed}
            colorScheme="purple"
            width="100%"
            isDisabled={organizations.length === 0}
          >
            Proceed with Selected Organization
          </Button>
          {
            organizations.length > 0 ? `` : (
              <>
                <Text textAlign="center" color="gray.500">or</Text>
                <Button onClick={onOpen} colorScheme="purple" width="100%">
                  <Icon as={AddIcon} mr={2} />
                  Create New Organization
                </Button>
                <Text textAlign="center" mt={4} color="red.500" fontWeight="bold">
                Note: 🌟 No organizations yet? No worries! 🌟
                You can either ask the org owner to send you an invite 📧, or take the leap and create your own awesome organization! 🚀
                </Text>
              </>
            )
          }

        </VStack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Organization</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={4} isRequired>
                <FormLabel>Organization Name</FormLabel>
                <Input
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  placeholder="Organization Name"
                  focusBorderColor="purple.400"
                />
              </FormControl>

              {/* <FormControl mb={4} isRequired>
                <FormLabel>Azure DevOps Base URL</FormLabel>
                <Input
                  value={azureBaseUrl}
                  onChange={(e) => setAzureBaseUrl(e.target.value)}
                  placeholder="https://dev.azure.com/yourorg"
                  focusBorderColor="purple.400"
                />
              </FormControl>

              <FormControl mb={4} isRequired>
                <FormLabel>Azure Devops Token</FormLabel>
                <InputGroup>
                  <Input
                    value={personalAccessToken}
                    onChange={(e) => setPersonalAccessToken(e.target.value)}
                    placeholder="Azure Devops Token"
                    type={showPassword ? 'text' : 'password'}
                    focusBorderColor="purple.400"
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      onClick={() => setShowPassword(!showPassword)}
                      variant="ghost"
                      size="sm"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl> */}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="purple" mr={3} onClick={handleCreateOrganization}>
                Create
              </Button>
              <Button variant="ghost" onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default OrganizationPage;
