const globalConfig = window._conf ? window._conf : {};


const environment = {
    ...globalConfig,
};


export const baseCerebrumUrl = environment.REACT_APP_BASE_CEREBRUM_URL || "https://api.fexz0.de/service/public/v1.0/"
export const baseDevasUrl = environment.REACT_APP_BASE_DEVAS_URL || "http://localhost:8081/v1.0/"
export const baseDevasWebsocketUrl = environment.REACT_APP_BASE_DEVAS_WEBSOCKET_URL || "ws://localhost:9092/v1.0/"
export const baseReviewPilotUrl = environment.REACT_APP_BASE_REVIEW_PILOT_URL || "http://localhost:8083/v1.0/"
export const baseNejiUrl = environment.REACT_APP_BASE_NEJI_URL || "http://localhost:8084/v1.0/"
export const baseNejiWebhookUrl = environment.REACT_APP_BASE_NEJI_WEBHOOK_URL || "http://localhost:8082/v1.0/"
export const baseHiruzenPlatformUrl = environment.REACT_APP_BASE_HIRUZEN_PLATFORM_URL || "http://localhost:8086/v1.0/"
export const baseHiruzenAdminUrl = environment.REACT_APP_BASE_HIRUZEN_ADMIN_URL || "https://api.fexz0.de/service/___/administrator/organization/v1.0/"
export const baseHiruzenInternalUrl = environment.REACT_APP_BASE_HIRUZEN_INTERNAL_URL || "http://localhost:8089/v1.0/"
export const baseIngestionUrl = environment.REACT_APP_BASE_INGESTION_URL || "https://api.fexz0.de/service/ingestion/v1.0"

export const roles = [
    {name: "Member", slug: 'member'},
    {name: "Org Owner", slug: 'org-owner'},
    {name: "Engg Manager", slug: 'engg-manager'}
];

export const roleHierarchy = {
    'org-owner': ['member', 'engg-manager', 'org-owner'],
    'engg-manager': ['member', 'engg-manager'],
    'member': ['member']
};

// Development Env

// export const baseDevasUrl = "http://localhost:8081/v1.0/";
// export const baseDevasWebsocketUrl = "ws://localhost:9092/v1.0/";
// export const baseReviewPilotUrl = "http://localhost:8001/v1.0/";
// export const baseIngestionUrl = "http://localhost:8002/v1.0";
// export const baseNejiUrl = "http://localhost:8084/v1.0/";
// export const baseNejiWebhookUrl = "http://localhost:8082/v1.0/";
// export const baseHiruzenAdminUrl = "http://localhost:8085/v1.0"

