import createRoutes from '../../utils/utilities';
import DocumentCreatorPage from './DocUI';
import DocumentsProjects from './DocumentsProjects';

export const documentRoutes = {
    documentsProjects: {
        name: "Projects",
        path: "/doccreator/projects",
        component: DocumentsProjects,
        private: true
    },
    documentProjectPage: {
        name: "Document Creator",
        path: "/doccreator/project/:projectId",
        component: DocumentCreatorPage,
        private: true
    },
};

const DocumentCreatorRoutes = createRoutes({ routes: documentRoutes });
export default DocumentCreatorRoutes;
