import { components } from "react-select";
import createRoutes from "../utils/utilities";
import LandingPage from "./landingPage";
import LandingPage2 from "./LandingPage2/index";

export const genericRoutes = {
  products: {
    name: "Home",
    path: "/products",
    component: LandingPage,
    private: true,
    isActive: true,
  },
  landingPage: {
    name: "Landing page",
    path: "/landing-page",
    component: LandingPage2,
    private: false,
    isActive: true,
  },
};

const GenericRoutes = createRoutes({ routes: genericRoutes });
export default GenericRoutes;
