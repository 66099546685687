import createRoutes from '../../utils/utilities';
import APIListPage from './APIListPage';
import { DataTable } from './MyExtensions';
import HomePage from './Home';

export const techDocBuilderRoutes = {
    homePage: {
        name: "Home Page",
        path: "/home",
        component: HomePage,
        private: true
    },
    apiListPage: {
        name: "API List",
        path: "/api-list",
        component: APIListPage,
        private: true
    },
    dataTable: {
        name: "Data Table",
        path: "/my-brds",
        component: DataTable,
        private: true
    },
};

const TechDocBuilderRoutes = createRoutes({ routes: techDocBuilderRoutes });
export default TechDocBuilderRoutes;