import React, { useState, useEffect, useRef } from "react";
import {
  TableContainer,
  Table,
  TableCaption,
  Tr,
  Td,
  Th,
  Thead,
  Tbody,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Center,
  useColorMode
} from "@chakra-ui/react";
import { Layout } from "../common/Layout";
import { useNavigate } from "react-router-dom";
import { AddIcon, SearchIcon, DeleteIcon } from "@chakra-ui/icons";
import { NoDataFound } from "../common/NoDataFound";
import { baseBRDBuilderUrl, baseCerebrumUrl } from "../../utils/constants";

export const DataTable = () => {
    const { setColorMode } = useColorMode();
    setColorMode("light");
    const [tableData, setTableData] = useState([]); // State to store the fetched data
    const [isLoading, setIsLoading] = useState(true); // Set initial loading to `true`
    const [filteredData, setFilteredData] = useState([]); // State for the filtered rows
    const [searchQuery, setSearchQuery] = useState("");
    const debounceTimeoutRef = useRef(null);
    const navigate = useNavigate();

    // Function to fetch data from API
    const getTableData = async () => {
        setIsLoading(true); // Set loading state to `true` before fetching data
        try {
            const email = localStorage.getItem("email"); // Retrieve email from local storage
            const response = await fetch(
                baseCerebrumUrl + "extensions?email=" + email, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            // Parse the response as JSON
            const data = await response.json();
            console.log("Data extensions:", data);

            // Update the state with the fetched data
            setTableData(data);
            setFilteredData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false); // Stop loading once data fetching completes or fails
        }
    };

    const createNewExtension = async () => {
        const email = localStorage.getItem("email");
        let resp = await fetch(baseCerebrumUrl + "extensions?email=" + email, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });
        resp = await resp.json();
        const token = localStorage.getItem("token");
        localStorage.clear();
        localStorage.setItem("current_extension_id", resp["inserted_row"]);
        localStorage.setItem("token", token);
        localStorage.setItem("email", email);
    }

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);

        // Clear the previous debounce timeout
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new debounce timeout
        debounceTimeoutRef.current = setTimeout(() => {
        if (value.length > 0) {
            const filtered = tableData.filter((row) =>
            row.brd_name?.toString().toLowerCase().includes(value)
            );
            setFilteredData(filtered); // Update the filtered data
        } else {
            setFilteredData(tableData); // Reset to all data
        }
        }, 300); // Adjust the debounce delay as needed (300ms is common)
    };

    const handleCreateNewExtension = () => {
        // Start showing the loader
        setIsLoading(true);
        createNewExtension();
        // Simulate some delay for creating the extension (replace with actual action)
        setTimeout(() => {
        setIsLoading(false);
        navigate("/home"); // Navigate to the desired route
        }, 1000); // Adjust timeout as needed or remove it entirely when adding real functionality
    };

    const handleViewExtension = async (rowId) => {
        let response = await fetch(`${baseCerebrumUrl}extensions?row_id=` + rowId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
        });
        let data = await response.json();
        console.log("Data: ", data.wizard_data);
        for (const key in data.wizard_data) {
        if (data.wizard_data.hasOwnProperty(key) && data.wizard_data[key] !== "null" && data.wizard_data[key] !== "[]") {
            localStorage.setItem(key, data.wizard_data[key]);
        }
        }
        localStorage.setItem("current_extension_id", "" + rowId);
        console.log("All fields have been stored in localStorage.");
        navigate("/home");
    }

    // Function to delete a specific extension
    const handleDeleteExtension = async (rowId) => {
        try {
        const response = await fetch(`${baseCerebrumUrl}extensions?row_id=` + rowId, {
            method: "DELETE",
            headers: {
            "Content-Type": "application/json"
            }
        });

        if (response.ok) {
            // Update the data by removing the deleted extension
            setTableData((prevData) => prevData.filter((row) => row.id !== rowId));
            setFilteredData((prevData) => prevData.filter((row) => row.id !== rowId));
            console.log(`Extension with ID ${rowId} has been deleted.`);
        } else {
            console.error("Failed to delete the extension");
        }
        } catch (error) {
        console.error("Error deleting the extension:", error);
        }
    };

    // Fetch data when the component mounts
    useEffect(() => {
        getTableData();
    }, []);

    return (
        <Layout>
        <Flex justify="flex-end" m={6}>
            <Button 
            colorScheme="purple"
            onClick={handleCreateNewExtension}
            isLoading={isLoading}
            leftIcon={!isLoading && <AddIcon />}
            isDisabled={isLoading}
            >
            Create New Design Doc
            </Button>
        </Flex>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6} m={6}>
            <Flex justify='space-between' mb={4}>
            {/* Add the search icon in the input group */}
            <InputGroup width='300px'>
                <InputLeftElement pointerEvents='none'>
                <SearchIcon color='gray.500' />
                </InputLeftElement>
                <Input
                placeholder='Document name'
                value={searchQuery}
                onChange={handleSearchChange}
                />
            </InputGroup>
            </Flex>
            <TableContainer maxHeight="500px" overflowY="auto">
            <Table variant="simple">
                <Thead position={"sticky"}>
                <Tr>
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th isNumeric>Action</Th>
                </Tr>
                </Thead>
                <Tbody>
                {isLoading ? (
                    <Tr>
                    <Td colSpan={3}>
                        <Center>
                        <Spinner size='lg' />
                        </Center>
                    </Td>
                    </Tr>
                ) : filteredData.length > 0 ? (
                    filteredData.map((row, index) => (
                        <Tr key={row.id}>
                          <Td>{index + 1}</Td> {/* ID column */}
                          <Td>{row.brd_name || 'Default'}</Td> {/* Name column */}
                          <Td isNumeric>
                            <Button
                              colorScheme='gray'
                              size='sm'
                              onClick={() => handleViewExtension(row.id)}
                            >
                              View
                            </Button>
                            <Button
                              ml={2}
                              colorScheme='red'
                              size='sm'
                              onClick={() => handleDeleteExtension(row.id)}
                            >
                              <DeleteIcon />
                            </Button>
                          </Td> {/* Action buttons column */}
                        </Tr>
                    ))
                ) : (
                    <NoDataFound forTable={true} colSpan={5} />
                )}
                </Tbody>
            </Table>
            </TableContainer>
        </Box>
        </Layout>
    );
};
