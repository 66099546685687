import React, { useState, useEffect } from 'react';
import {
  Box, Flex, Heading, Input, Button, useColorModeValue, useToast, HStack, Image, useColorMode, VStack, Text, Divider, FormErrorMessage, Icon
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons'; // Import the back icon
import { useNavigate } from 'react-router-dom';
import ProfileMenu from './MenuProfile';
import { baseDevasUrl, baseReviewPilotUrl } from '../../utils/constants';
import { getOrgNameById, getSessionDetails } from '../../utils/utilities';
import PrHeader from '../ReviewPilot/PrHeader';

const ConfigurePage = () => {
  const [adoPat, setAdoPat] = useState('');
  const [visibleToolTip, setVisibleToolTip] = useState(false);
  const [adoURL, setAdoURL] = useState('');
  const [errors, setErrors] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const boxBg = useColorModeValue('white', 'gray.800');
  const mainBg = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');
  const hoverColor = useColorModeValue('purple.500', 'purple.200');
  const { setColorMode } = useColorMode();
  setColorMode('light');
  const navigate = useNavigate();
  const toast = useToast();

  const fetchTokens = async () => {
    const currentRoleSlug = getSessionDetails().session?.passport?.user?.roleSlug;
    if (currentRoleSlug === "org-owner") {
      const resp = await fetch(`${baseReviewPilotUrl}org_details/${getSessionDetails().session.passport.user.orgId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (resp.ok) {
        const data = await resp.json();
        setAdoPat(data.data.vc_pat);
        setAdoURL(data.data.vc_url);
        setIsUpdate(true);
        setVisibleToolTip(false);
      } else {
        setIsUpdate(false);
        setAdoPat('');
        setAdoURL('');
        setVisibleToolTip(true);
      }

    } else {
      try {
        const response = await fetch(`${baseDevasUrl}user/config/${localStorage.getItem("email")}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();

        if (data.success) {
          setAdoPat(data.data.azure_pat_token);
          setIsUpdate(true);
          setVisibleToolTip(false);
        } else {
          setIsUpdate(false);
          setAdoPat('');
          setAdoURL('');
          setVisibleToolTip(true);
        }
      } catch (error) {
        setVisibleToolTip(true);
        toast({
          title: "Error",
          description: "An error occurred while loading the tokens.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchTokens();
  }, [toast]);

  const refreshData = async () => {
    await fetchTokens();
  }

  const handleSaveTokens = async () => {
    const userEmail = localStorage.getItem("email");
    const currentRoleSlug = getSessionDetails().session?.passport?.user?.roleSlug;

    const validationErrors = {};
    if (!adoPat) {
      validationErrors.error = 'Azure PAT is required';
    } else if (currentRoleSlug === "org-owner" && !adoURL) {
      validationErrors.error = 'Azure Devops Url is required';
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      toast({
        title: "Validation Error",
        description: validationErrors.error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    const method = isUpdate ? 'PUT' : 'POST';
    const url = isUpdate ? `${baseDevasUrl}user/config/${userEmail}` : `${baseDevasUrl}user/config`;
    const org_id = getSessionDetails().session?.passport?.user?.orgId;
    if (currentRoleSlug === "org-owner") {
      const orgDetails = {
        org_id: org_id,
        vc_pat: adoPat,
        vc_url: adoURL,
        ...(method === 'POST' && { org_name: getOrgNameById(getSessionDetails().session?.passport?.user?.workspace, getSessionDetails().session?.passport?.user?.orgId) })
      };
      let data = await fetch(`${baseReviewPilotUrl}org_details`, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(orgDetails)
      });

      data = await data.json();
      if (data.success) {
        setVisibleToolTip(false);
        toast({
          title: "Success",
          description: "The tokens have been saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          navigate(-1);
        }, 500);
      } else {
        if (data.errors?.[0] === "Organization Already Exists") {
          setVisibleToolTip(true);
          toast({
            title: "Error",
            description: "Organization Details Already Exists",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          setVisibleToolTip(true);
          toast({
            title: "Error",
            description: "Failed to save the tokens.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } else {
      try {
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email_id: userEmail, azure_pat_token: adoPat })
        });
  
        const data = await response.json();
  
        if (data.success) {
          setVisibleToolTip(false);
          toast({
            title: "Success",
            description: "The tokens have been saved.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(() => {
            navigate(-1);
          }, 500);
        } else {
          if (data.errors?.[0] === "Organization Already Exists") {
            setVisibleToolTip(true);
            toast({
              title: "Error",
              description: data.errors?.[0],
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            setVisibleToolTip(true);
            toast({
              title: "Error",
              description: "Failed to save the tokens.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        setVisibleToolTip(true);
        toast({
          title: "Error",
          description: "An error occurred while saving the tokens.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box bg={mainBg} minH="100vh">
      <PrHeader visibleToolTip={visibleToolTip} style={{display: 'none'}} changeWorkspace={refreshData}/>
      <Flex justifyContent="space-between" alignItems="center" p={4} bg={boxBg} boxShadow="sm">
        <Flex alignItems="center">
          <svg width="50" height="50" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_26659)">
            <path d="M5.14498 0.59082H1.74123H1.73073V0.60832L0.00523376 3.64282L1.73073 6.59332L3.46148 3.55007H11.522L13.9947 0.59082H5.14498Z" fill="#A380FF"/>
            <path d="M0.909912 10.459L2.63541 13.4095L4.36616 10.3662L5.81866 7.81472H9.00366L10.7992 4.85547H4.10366L0.909912 10.459Z" fill="#A380FF"/>
            </g>
            <defs>
            <clipPath id="clip0_1073_26659">
            <rect width="13.9895" height="12.8188" fill="white" transform="translate(0.00523376 0.59082)"/>
            </clipPath>
            </defs>
          </svg>
          <Heading color="#A380FF" size="lg">FyniX</Heading>
        </Flex>
        <ProfileMenu onWorkSpaceChange={refreshData} />
      </Flex>
      <Flex justifyContent="flex-start" p={4}>
        <Box
          as="span"
          cursor="pointer"
          onClick={() => navigate(-1)}
          _hover={{ 
            color: hoverColor, 
            transform: "scale(1.1)",
            transition: "all 0.2s ease-in-out"
          }}
        >
          <ArrowBackIcon w={6} h={6} />
        </Box>
      </Flex>
      <Box p={10}>
        <Flex justifyContent="center" mb={8}>
          <Heading color={textColor}>Configure Tokens</Heading>
        </Flex>
        <VStack spacing={4} justifyContent={"center"}>
          <Text fontWeight={"bold"}><Text color="red" as="span">*</Text>Azure Personal Access Token (Org Level)</Text>
          <Input
            placeholder="Azure Devops PAT (Personal Access Token)"
            value={adoPat}
            onChange={(e) => setAdoPat(e.target.value)}
            focusBorderColor='purple.400'
            width={"50%"}
            type="password" isRequired="true"
          />
          {errors.adoPat && <FormErrorMessage>{errors.adoPat}</FormErrorMessage>}
          {
            getSessionDetails().session?.passport?.user?.roleSlug === "org-owner" && (
              <>
                <Text fontWeight={"bold"}><Text color="red" as="span">*</Text>Azure Devops Base URL</Text>
                <Input
                  placeholder="Azure Devops Base URL"
                  value={adoURL}
                  onChange={(e) => setAdoURL(e.target.value)}
                  focusBorderColor='purple.400'
                  width={"50%"}
                />
              </>
            )
          }
          <Divider />
          <HStack justifyContent={"center"}>
            <Button colorScheme="purple" onClick={handleSaveTokens}>Save</Button>
            <Button variant="outline" onClick={() => navigate(-1)}>Cancel</Button>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default ConfigurePage;
