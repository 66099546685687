import createRoutes from '../../utils/utilities';
import PrRepositories from './PrRepositories';
import ConfigurationPage from './RepositoryConfig';
import Dashboard from './PrDashboard';

export const reviewPilotRoutes = {
    dashboard: {
        name: "Dashboard",
        path: "/dashboard",
        component: Dashboard,
        private: true
    },
    prRepositories: {
        name: "Repositories",
        path: "/repositories",
        component: PrRepositories,
        private: true
    },
    repositoryConfiguration: {
        name: "Configuration",
        path: "/configuration/:repoId",
        component: ConfigurationPage,
        private: true
    },
};

const ReviewPilotRoutes = createRoutes({ routes: reviewPilotRoutes });
export default ReviewPilotRoutes;