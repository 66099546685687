import createRoutes from '../../utils/utilities';
import SequenceDiagramProjects from './SequenceDiagramProjects';
import SequenceDiagram from './SequenceDiagramUI';

export const diagramCreatorRoutes = {
    diagramProjects: {
        name: "Projects",
        path: "/diagrams/projects",
        component: SequenceDiagramProjects,
        private: true
    },
    diagramProjectPage: {
        name: "Diagram Creator",
        path: "/diagrams/project/:projectId",
        component: SequenceDiagram,
        private: true
    },
};

const DiagramCreatorRoutes = createRoutes({ routes: diagramCreatorRoutes });
export default DiagramCreatorRoutes;