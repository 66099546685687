import React, { useEffect } from 'react';
import {
  Flex,
  Text,
  HStack,
  Icon,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaHome, FaExclamationTriangle } from 'react-icons/fa';
import ProfileMenu from '../common/MenuProfile';

const PrHeader = ({ changeWorkspace, visibleToolTip = false,  style = {}, clearFilters }) => {
  // Define color modes for text, icons, and hover effects
  const textColor = useColorModeValue('gray.600', 'gray.600');
  const iconColor = useColorModeValue('purple.600', 'purple.600');
  const hoverTextColor = useColorModeValue('purple.500', 'purple.500');
  const alertBgColor = useColorModeValue('red.100', 'red.100');
  const alertTextColor = useColorModeValue('red.700', 'red.700');

  return (
    <Flex
      bg={useColorModeValue('white', 'white')}
      p="4"
      color={textColor}
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor={useColorModeValue('gray.200', 'gray.200')}
      boxShadow="sm"
      style={style}
    >
      <HStack spacing={4} alignItems="center">
        {/* <Link href="/products" style={{ display: 'flex', alignItems: 'center' }}> */}
          {/* <Icon as={FaHome} boxSize={5} color={iconColor} mr={3} /> */}
          <Text
            fontSize="2xl"
            fontWeight="bold"
            cursor="auto"
            // _hover={{ color: hoverTextColor, textDecoration: 'underline' }}
            transition="color 0.3s ease-in-out"
          >
            Code Quality Agent
          </Text>
        {/* </Link> */}
      </HStack>
      <HStack spacing={4} alignItems="center">
        {visibleToolTip && (
          <Flex
            px="3"
            py="2"
            rounded="md"
            bg={alertBgColor}
            color={alertTextColor}
            display="flex"
            alignItems="center"
            boxShadow="base"
          >
            <FaExclamationTriangle style={{ marginRight: '8px' }} />
            <Text fontSize="sm" fontWeight="bold">
              Please complete your token configuration
              <Link href="/config" style={{ textDecoration: 'underline', marginLeft: '4px' }}>
                here
              </Link>
            </Text>
          </Flex>
        )}
        <ProfileMenu onWorkSpaceChange={changeWorkspace} clearFilters={clearFilters} />
      </HStack>
    </Flex>
  );
};

export default PrHeader;
