import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { WorkspaceProvider } from "./utils/workspaceContext";

import GenericRoute from "./comp/routes";
import DocumentCreatorRoutes from "./comp/Documents/routes";
import DiagramCreatorRoutes from "./comp/SequenceDiagram/routes";
import AuthRoutes from "./comp/Auth/routes";
import CommonRoutes from "./comp/common/routes";
import ReviewPilotRoutes from "./comp/ReviewPilot/routes";
import DevasRoutes from "./comp/Devas/routes";
import RagRoutes from "./comp/CustomRAG/routes";
import TechDocBuilderRoutes from "./comp/TechDocBuilder/routes";
import VisualizeGraphRoutes from "./comp/GraphVisualize/routes";
import RepoRoutes from "./comp/Repositories/routes";

import { MantineProvider } from "@mantine/core";

// Import other pages here

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <ChakraProvider>
        <WorkspaceProvider>
          <Router>
            <Routes>
              {/* <Route path="/" element={<AuthPage />} /> */}
              {/* <Route path="/config" element={<ConfigurePage />} /> */}
              {/* <Route path="/oauth-callback" element={<OAuthCallback />} /> */}
              {/* <Route path="/auth/emailVerification" element={<EmailVerification />} /> */}
              {/* <Route path="/verification-success" element={<VerificationSuccess />} /> */}
              {/* <Route path="/microsoft-oauth-callback" element={<MicrosoftOauthCallback />} /> */}
              {/* <Route path="/devas/:projectId" element={<DevasPage />} /> */}
              {/* <Route path="/devas/coder" element={<DevasBrdPage />} /> */}
              {/* <Route path="/home" element={<PrivateRoute component={HomePage} />} /> */}
              {/* <Route path="/start-devas" element={<PrivateRoute component={StartDevasPage} />} /> */}
              {/* <Route path="/api-list" element={<APIListPage />} /> */}
              {/* Define other routes here */}
              {/* <Route path="my-brds" element={<PrivateRoute component={DataTable} />} /> */}
              {/* <Route path="temp" element={<PrivateRoute component={Integrations} />} /> */}
              {/* <Route path="/configuration/:repoId" element={<PrivateRoute component={ConfigurationPage} />} /> */}
              {/* <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} /> */}
              {/* <Route path="/repositories" element={<PrivateRoute component={PrRepositories} />} /> */}
              {/* <Route path="/products" element={<PrivateRoute component={LandingPage} />} name="home" /> */}
              {/* <Route path="/doccreator/:projectId" element={<PrivateRoute component={DOCHubPage} />} /> */}
              {/* <Route path="/devas/projects" element={<PrivateRoute component={DevasProject} />} /> */}
              {/* <Route path="/quipwhiz" element={<SearchEngine />} /> */}
              {/* <Route path="/auth/setPassword" element={<ForgotPasswordForm />} /> */}
              {/* <Route path="/no-workspace-found" element={<NoWorkspaceFound />} /> */}
              {/* <Route path="/org-selection" element={<OrganizationPage />} /> */}
              {/* <Route path="/auth/signup" element={<RegistrationPage />} /> */}
              {/* <Route path="/documents/projects" element={<PrivateRoute component={DocumentsProjects} />} name="docgenerator-projects" /> */}
              {/* <Route path="/diagrams/projects" element={<PrivateRoute component={SequenceDiagramProjects} />} />
						<Route path="/diagrams/:projectId" element={<PrivateRoute component={SequenceDiagram} />} /> */}

              {GenericRoute}
              {CommonRoutes}
              {AuthRoutes}
              {ReviewPilotRoutes}
              {DocumentCreatorRoutes}
              {DiagramCreatorRoutes}
              {DevasRoutes}
              {RagRoutes}
              {TechDocBuilderRoutes}
              {VisualizeGraphRoutes}
              {RepoRoutes}
            </Routes>
          </Router>
        </WorkspaceProvider>
      </ChakraProvider>
    </MantineProvider>
  );
}

export default App;
