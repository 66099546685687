import React, { useState } from 'react';
import { Box, Button, Input, VStack, Flex, Text, Image, useToast, Center, IconButton, Tooltip, useColorMode } from "@chakra-ui/react";
import { AddIcon, DeleteIcon, ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';

function StartDevasPage() {
    const { setColorMode } = useColorMode();
    setColorMode("light");
    const [gitUrl, setGitUrl] = useState("");
    const [quipLinks, setQuipLinks] = useState([""]);
    const navigate = useNavigate();
    const toast = useToast();

    const handleGoToDevas = () => {
        if (!gitUrl && quipLinks.every(link => link === "")) {
            toast({
                title: "Invalid request",
                description: "Add the required github URL and quip links",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        navigate('/devas', { state: { gitUrl, quipLinks } });
    };

    const addQuipLinkInput = () => {
        setQuipLinks([...quipLinks, ""]);
    };

    const removeQuipLinkInput = (index) => {
        if (quipLinks.length > 1) {
            setQuipLinks(quipLinks.filter((_, i) => i !== index));
        }
    };

    const updateQuipLink = (index, value) => {
        const updatedQuipLinks = quipLinks.map((link, i) => i === index ? value : link);
        setQuipLinks(updatedQuipLinks);
    };

    return (
        <Center height="100vh">
            <VStack spacing={6} p={8} align="stretch" maxWidth="600px" w="full">
                <Flex align="center" justifyContent="center" mb={5}>
                    <svg width="50" height="50" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1073_26659)">
                        <path d="M5.14498 0.59082H1.74123H1.73073V0.60832L0.00523376 3.64282L1.73073 6.59332L3.46148 3.55007H11.522L13.9947 0.59082H5.14498Z" fill="#A380FF"/>
                        <path d="M0.909912 10.459L2.63541 13.4095L4.36616 10.3662L5.81866 7.81472H9.00366L10.7992 4.85547H4.10366L0.909912 10.459Z" fill="#A380FF"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1073_26659">
                        <rect width="13.9895" height="12.8188" fill="white" transform="translate(0.00523376 0.59082)"/>
                        </clipPath>
                        </defs>
                    </svg>
                    <Text fontSize="2xl" fontWeight="normal" ml={2}
                        sx={{
                            background: '#A380FF',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            backgroundClip: 'text',
                        }}
                    >FyniX</Text>
                </Flex>
                <Flex align="center">
                    <Input
                        placeholder="GitHub Repository URL"
                        value={gitUrl}
                        onChange={(e) => setGitUrl(e.target.value)}
                        mr={2}
                    />
                    <Tooltip label="GitHub Repository URL you want to make changes to" hasArrow>
                        <IconButton
                            aria-label="Info for Git URL"
                            icon={<InfoIcon />}
                            size="sm"
                            variant="ghost"
                        />
                    </Tooltip>
                </Flex>
                {quipLinks.map((link, index) => (
                    <Flex key={index} align="center">
                        <Input
                            placeholder={`Enter Quip Document Link ${index + 1}`}
                            value={link}
                            onChange={(e) => updateQuipLink(index, e.target.value)}
                            flex="1"
                        />
                        <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => removeQuipLinkInput(index)}
                            aria-label="Remove Quip Link"
                            ml={2}
                            colorScheme="red"
                            isDisabled={quipLinks.length === 1}
                        />
                    </Flex>
                ))}
                <Button leftIcon={<AddIcon />} onClick={addQuipLinkInput} colorScheme="green">Add Another Quip Link</Button>
                <Button colorScheme="gray" onClick={handleGoToDevas} rightIcon={<ExternalLinkIcon />}>Go to Devas</Button>
            </VStack>
        </Center>
    );
}

export default StartDevasPage;
