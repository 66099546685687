import "@mantine/charts/styles.css";
import {
  Accordion,
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Group,
  Input,
  Paper,
  Popover,
  SegmentedControl,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import "@mantine/core/styles.css";
import {
  IconArrowUpRight,
  IconBellRinging,
  IconBoltFilled,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandX,
  IconCalendar,
  IconChevronDown,
  IconChevronUp,
  IconCode,
  IconFileCode,
  IconGlobe,
  IconLanguage,
  IconMail,
  IconMenu2,
  IconNotebook,
  IconPlus,
  IconRefresh,
  IconStarFilled,
  IconStarHalfFilled,
  IconWand,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import "./index.css";

// ################################################################################
// Navbar
// ################################################################################

const DropList = ({ title, list }) => {
  const [isExpanded, setExpanded] = useState(false);
  const timeoutRef = useRef(null); // To store the timeout reference

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current); // Clear any pending close actions
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    // Delay the closure to allow mouse traversal over small gaps
    timeoutRef.current = setTimeout(() => setExpanded(false), 200);
  };

  return (
    <Popover
      opened={isExpanded}
      onClose={() => setExpanded(false)}
      position="bottom"
      shadow="md"
    >
      <Popover.Target>
        <Flex
          align="center"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Text color="#C9C9C9" fw={500} mr={8}>
            {title}
          </Text>
          {isExpanded ? (
            <IconChevronUp size={16} color="#C9C9C9" stroke={2} />
          ) : (
            <IconChevronDown size={16} color="#C9C9C9" stroke={2} />
          )}
        </Flex>
      </Popover.Target>

      {isExpanded && (
        <Popover.Dropdown
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          bg="#141414"
          bd={0}
          p={16}
          w="max-content"
          maw={400}
          style={{
            backgroundColor: "rgba(20, 20, 20, 0.8)",
            backdropFilter: "blur(20px)",
            boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Flex direction="column" columnGap={12}>
            {list.map((item) => (
              <Flex p={12} className="droplist-item">
                <ThemeIcon size="lg" radius="md" color="violet" mr={12} p={8}>
                  {item.icon ?? <IconNotebook stroke={2} />}
                </ThemeIcon>
                <Box miw={150}>
                  <Text color="#fff" mb={8}>
                    {item.title}
                  </Text>
                  <Text color="#A8A8A8">{item.subtitle}</Text>
                </Box>
              </Flex>
            ))}
          </Flex>
        </Popover.Dropdown>
      )}
    </Popover>
  );
};
const Navbar = () => {
  const [isHamburgerEnabled, setIsHamburgerEnabled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsHamburgerEnabled(window.innerWidth <= 930);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const productsHoverList = [
    {
      title: "AI Code Assistant",
      subtitle: "Your daily coding companion for VS Code and PyCharm.",
    },
    {
      title: "Code Quality Agent",
      subtitle: "Track your pull requests, repository reviews and more.",
    },
  ];

  const resourcesHoverList = [
    {
      title: "Documentation",
      subtitle: "Know Fynix better in detail.",
    },
  ];

  return (
    <Flex justify="center">
      <Group maw={1440} justify="space-between" direction="row" w="100%">
        <Box className="landing-page-logo" py={18} mx={35}>
          <img src="/fynix-logo-white.png" />
        </Box>

        {isHamburgerEnabled ? (
          <Flex mr={16}>
            <IconMenu2 color="#C9D0E3" stroke={2} />
          </Flex>
        ) : (
          <>
            <Flex align="center" columnGap={32}>
              <DropList title="Products" list={productsHoverList} />
              <DropList title="Resources" list={resourcesHoverList} />
              <Box>
                <Text color="#C9C9C9" fw={500}>
                  Pricing
                </Text>
              </Box>
              <Box>
                <Text color="#C9C9C9" fw={500}>
                  About
                </Text>
              </Box>
              <Box>
                <Text color="#C9C9C9" fw={500}>
                  Contact
                </Text>
              </Box>
            </Flex>

            <Flex align="center" mr={80}>
              <Button color="transparent">
                <Text color="#A380FF">Log In </Text>
              </Button>
              <Button color="#8455FF">Sign Up</Button>
            </Flex>
          </>
        )}
      </Group>
    </Flex>
  );
};
// ################################################################################

// ################################################################################
// Features
// ################################################################################

const sections = [
  {
    id: "model-selection",
    title: "Multiple Model Selection & Regeneration",
    icon: IconRefresh,
  },
  {
    id: "code-completion",
    title: "AI Code Completion & Generation",
    icon: IconCode,
  },
  {
    id: "context-codebase",
    title: "Context From Codebase",
    icon: IconFileCode,
  },
  {
    id: "language-understanding",
    title: "Natural Language Understanding",
    icon: IconLanguage,
  },
  { id: "web-info", title: "Fetch Real-time Web Information", icon: IconGlobe },
];

const Features = ({ title }) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const scrollableBoxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableBoxRef.current) {
        const { scrollTop, clientHeight, scrollHeight } =
          scrollableBoxRef.current;
        const sectionHeight = clientHeight * 0.9;
        const activeSectionIndex = Math.min(
          Math.floor(scrollTop / sectionHeight),
          sections.length - 1
        );
        setActiveSection(sections[activeSectionIndex].id);
      }
    };

    const scrollableBox = scrollableBoxRef.current;
    if (scrollableBox) {
      scrollableBox.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableBox) {
        scrollableBox.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement && scrollableBoxRef.current) {
      const topOffset =
        sectionElement.offsetTop - scrollableBoxRef.current.offsetTop;
      scrollableBoxRef.current.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  return (
    <Stack align="center" justify="center" h="100vh" mt={220}>
      <Box
        w="100%"
        h="100%"
        style={{
          borderRadius: "8px",
          overflowY: "scroll",
        }}
      >
        <Flex justify="center">
          <img src="/closing-tag.png" />
        </Flex>
        <Flex justify="center" className="features-title">
          {title}
        </Flex>

        <Flex
          columnGap={32}
          justify="center"
          ref={scrollableBoxRef}
          mx="auto"
          mt={60}
          px="10vw"
          w="100%"
          h="70%"
          style={{ overflowY: "auto" }}
        >
          <Flex direction="column" w="40%" pos="sticky" top="0">
            {sections.map((section) => (
              <Flex
                justify="flex-start"
                key={section.id}
                onClick={() => scrollToSection(section.id)}
                p={24}
                align="center"
                mb={32}
                className={`${
                  activeSection === section.id
                    ? "features-tabs-text-active"
                    : ""
                } features-tabs-text`}
              >
                <ThemeIcon
                  variant="filled"
                  style={{
                    opacity: `${activeSection === section.id ? 1 : 0.7}`,
                    background: "#D946EF26",
                  }}
                  mr={12}
                >
                  <section.icon size={36} />
                </ThemeIcon>
                <Box>{section.title}</Box>
              </Flex>
            ))}
          </Flex>

          <Box withBorder w="60%">
            {sections.map((section) => (
              <Paper key={section.id} id={section.id} h="80vh">
                <Text size="xl" fw={700}>
                  {section.title}
                </Text>
              </Paper>
            ))}
          </Box>
        </Flex>
      </Box>
    </Stack>
  );
};

// ################################################################################

// ################################################################################
// FynixOffersCard
// ################################################################################

const FynixOffersCard = ({ image, title, subTitle }) => {
  return (
    <Box className="fynix-offers-card" pt={32} mah={460}>
      <Flex direction="column" px={32}>
        <Text className="fynix-offers-title">{title}</Text>
        <Text className="fynix-offers-subtitle" mt={16}>
          {subTitle}
        </Text>
      </Flex>
      <Box w="82%" className="fynix-offers-image">
        <img src={image} className="fynix-offers-img" />
      </Box>

      <Flex justify="center">
        <Button
          leftSection={<IconArrowUpRight stroke={2} />}
          variant="outline"
          color="violet"
          pos="relative"
          className="fynix-offers-btn"
        >
          Explore Now
        </Button>
      </Flex>
    </Box>
  );
};

// ################################################################################

// ################################################################################
// NotifyMeCards
// ################################################################################

const NotifyMeCards = ({
  title = "AI Document Creator",
  subTitle = "Helps managers build go-to-market strategy plans, OKRs documents, product launch checklists, PRDs, BRDs, and many more.",
}) => {
  return (
    <Box pos="relative" mb={10}>
      <Box pos="absolute" className="coming-soon-overlay"></Box>
      <Flex
        direction="column"
        className="notify-me-card"
        p={32}
        style={{ zIndex: "-1" }}
      >
        <Text className="notify-card-title">{title}</Text>
        <Text className="notify-card-subtitle" mt={16}>
          {subTitle}
        </Text>

        <Flex justify="center" mb={80}>
          <Button
            mt={52}
            color="violet"
            variant="outline"
            leftSection={<IconBellRinging stroke={2} />}
          >
            Notify Me
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

// ################################################################################

// ################################################################################
// Feedback
// ################################################################################

const Feedback = () => {
  const userFeedback = [
    {
      title: "Lightning-quick and Powerful ⚡",
      subtitle:
        "Fynix has been a game-changer for our development team! As a tool for pull request reviews using AI, it has significantly increased our productivity and streamlined our code review process. Seriously, it's been a real lifesaver! With it, I feel more confident in the quality of my code.",
      iconPath: "/user1.png",
      reviewerName: "Avijeet Shrivastava",
      reviewerPosition: "Product Designer at Fynd",
      stars: 5,
    },
    {
      title: "Great alternative to expensive code generation tools",
      subtitle:
        "Fynix has been a game-changer for our development team! As a tool for pull request reviews using AI, it has significantly increased our productivity and streamlined our code review process. Seriously, it's been a real lifesaver! With it, I feel more confident in the quality of my code.",
      iconPath: "/user2.png",
      reviewerName: "Sayli Chaudhari",
      reviewerPosition: "Program Manager at Fynd",
      stars: 4.5,
    },
    {
      title: "One stop for a scalable solution",
      subtitle:
        "We recently used Fynix into our Jio repository, and the impact has been remarkable. Reviewing pull requests now takes half the time it used to. This tool not only benefits the PR reviewers but also frequently assists the authors by identifying potential edge cases.",
      iconPath: "/user3.png",
      reviewerName: "Ushank Radadiya",
      reviewerPosition: "SDE at Fynd",
      stars: 5,
    },
    {
      title: "Most intuitive coding assistant by far",
      subtitle:
        "Fynix stands at the forefront of AI integration in code editing, offering an unparalleled development experience. Its focus on privacy, combined with the seamless migration from VSCode, makes it a compelling choice for developers looking to leverage AI.",
      iconPath: "/user4.png",
      reviewerName: "Purav Shah",
      reviewerPosition: "Engineering Manager at Fynd",
      stars: 5,
    },
    {
      title: "A significant time saver for org",
      subtitle:
        "Fynix serves as a co-pilot for our PR reviewers, enhancing efficiency and expediting the PR closure process with precision, speed and quality. It’s a valuable tool for our monthly and yearly org-wide review cycles.",
      iconPath: "/user5.png",
      reviewerName: "Kushan Shah",
      reviewerPosition: "CTPO at Fynd",
      stars: 5,
    },
  ];

  const FeedbackCard = ({
    title,
    subtitle,
    iconPath,
    reviewerName,
    reviewerPosition,
    stars = "4",
  }) => {
    return (
      <Box className="feedback-card" w="100%" p={24}>
        <Flex gap={2} mb={16}>
          {Array(parseInt(stars))
            .fill(0)
            .map((_, index) => (
              <IconStarFilled
                key={index}
                color="#A380FF"
                width={16}
                height={16}
              />
            ))}
          {parseFloat(stars) - parseInt(stars) > 0 && (
            <IconStarHalfFilled color="#A380FF" width={16} height={16} />
          )}
        </Flex>
        <Text fw="600" color="white">
          {title}
        </Text>

        <Text color="#A8A8A8" mb={32}>
          {subtitle}
        </Text>

        <Flex>
          <Avatar src={iconPath} size="lg" radius="xl" mr={16} />
          <Flex direction="column" justify="center">
            <Text fw="600" color="white">
              {reviewerName}
            </Text>
            <Text color="#A8A8A8" mb={32}>
              {reviewerPosition}
            </Text>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <Flex justify="center" className="feedback-container">
      <Flex>
        <Flex direction="column" py={120} px={80}>
          <Box>
            <Flex justify="center" direction="column" align="center" gap={24}>
              <Box className="feedback-section-title">
                What Our Users Say About Us
              </Box>
              <Flex direction="column" align="center">
                <Box className="feedback-section-subtitle">
                  A trusted community that uses Fynix every day to code,
                </Box>
                <Box className="feedback-section-subtitle">
                  organize repos, and prioritize seamless workflow.
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Flex
            direction={{ base: "column", md: "row" }}
            columnGap={32}
            mt={60}
            w="100%"
          >
            {userFeedback.slice(0, 2).map((user) => (
              <FeedbackCard {...user} key={user.title} />
            ))}
          </Flex>

          <Flex
            direction={{ base: "column", md: "row" }}
            columnGap={32}
            mt={32}
            w="100%"
          >
            {userFeedback.slice(2, userFeedback.length).map((user) => (
              <FeedbackCard {...user} key={user.title} />
            ))}
          </Flex>
        </Flex>

        <Flex direction={{ base: "column", md: "row" }}></Flex>
      </Flex>
    </Flex>
  );
};

// ################################################################################

// ################################################################################
// FAQ
// ################################################################################

const Faq = () => {
  const [topic, setTopic] = useState("Product");
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    setFaqs(faqsStore[topic]);
  }, []);

  const faqsStore = {
    Product: [
      {
        value: "What is Fynix?",
        description:
          "Fynix is an AI programming assistant that helps developers streamline their workflow through features like code autocomplete, project scaffolding, code translation, and more.",
      },
      {
        value: "How do I install Fynix in Visual Studio Code?",
        description:
          "Visit the Fynix Copilot Marketplace page and click the 'Install' button. After installation, log in to start using Fynix.",
      },
      {
        value: "What programming languages does Fynix support?",
        description:
          "Fynix supports multiple programming languages including TypeScript, React, JavaScript, Python, and can translate code across different languages using the /translate command.",
      },
      {
        value: "How does the code autocomplete feature work in Fynix?",
        description:
          "Fynix’s code autocomplete provides real-time suggestions and completions based on your current code context. You can enable or disable inline autocomplete in the settings and adjust the delay for suggestions.",
      },
      {
        value: "What is the /complete command?",
        description:
          "The /complete command helps complete partial code by analyzing the context of your current project, providing faster coding with fewer errors.",
      },
      {
        value: "Can Fynix help generate unit tests?",
        description:
          "Yes, using the /test command, Fynix automatically generates unit tests for selected functions, streamlining the testing process.",
      },
      {
        value: "How can I refactor my code with Fynix?",
        description:
          "The /refactor command suggests improvements to your code’s structure, readability, and performance based on the selected code block.",
      },
      {
        value: "What does the /new command do?",
        description:
          "The /new command helps scaffold a new project by generating the required folder structure, configuration files, and boilerplate code tailored to your project’s needs.",
      },
      {
        value: "How does Fynix support cross-language code translation?",
        description:
          "With the /translate command, Fynix can translate code from one language to another. Simply input the target language to translate selected code snippets.",
      },
      {
        value: "Can Fynix help document my code?",
        description:
          "Yes, Fynix can generate documentation for your code using the /doc command and provide detailed explanations with the /explain command.",
      },
      {
        value: "What is Fynix’s Text to Code feature?",
        description:
          "The Text to Code feature allows you to translate plain text requirements into actionable code snippets, saving time and reducing errors in development.",
      },
      {
        value: "How does the /fix command work?",
        description:
          "The /fix command identifies and resolves errors in your code, ensuring proper functionality.",
      },
      {
        value: "What is Workspace Analysis?",
        description:
          "The @Workspace command allows Fynix to analyze your entire project repository and offer context-aware suggestions, improving your workflow efficiency.",
      },
      {
        value: "Can I customize Fynix’s behavior?",
        description:
          "Yes, Fynix allows you to set custom rules for AI behavior, such as specific coding practices or preferred languages.",
      },
      {
        value: "How do I export conversations in Fynix?",
        description:
          "You can export entire conversations, including code snippets and explanations, in markdown format for sharing or documentation purposes.",
      },
      {
        value: "How does Fynix help with project scaffolding?",
        description:
          "Fynix assists in project scaffolding by organizing directory structures, configuration files, and boilerplate code through the /new command.",
      },
      {
        value: "Can I use Fynix in collaborative environments?",
        description:
          "Yes, Fynix supports interactions like pinning important code snippets, reviewing Git context, and sharing feedback in collaborative coding environments.",
      },
    ],
    Pricing: [
      {
        value: "What is the pricing for Fynix?",
        description:
          "Fynix offers custom pricing based on individual or company usage. Please reach out to our team for a personalized pricing plan.",
      },
      {
        value: "Is there free access to Fynix?",
        description:
          "Yes, Fynix is currently providing free access for a limited time period. Take advantage of this offer by signing up now!",
      },
    ],
    Other: [
      {
        value: "Is Fynix free to use?",
        description:
          "Fynix offers both free and premium plans, depending on the features and level of usage. Check the pricing page for more details.",
      },
      {
        value: "How can I report bugs or issues with Fynix?",
        description:
          "You can report bugs directly through the 'Report Bug' option in the Fynix interface or by reaching out to support via support email.",
      },
      {
        value: "Does Fynix work offline?",
        description:
          "Fynix requires an active internet connection for most features, as it leverages cloud-based AI models to provide accurate suggestions and interactions.",
      },
      {
        value: "What platforms and IDEs does Fynix support?",
        description:
          "Fynix is currently available for Visual Studio Code, with possible support for additional IDEs in future updates.",
      },
      {
        value: "How do I manage API keys for custom integrations?",
        description:
          "You can manage your API keys, like OpenAI or Anthropic keys, through the 'Custom API Keys' section in Fynix settings.",
      },
      {
        value: "Does Fynix store my code or data?",
        description:
          "Fynix does not store any of your code or data permanently. It temporarily processes your data to provide suggestions or actions.",
      },
      {
        value: "How can I customize Fynix for my development workflow?",
        description:
          "You can customize Fynix’s behavior by configuring auto-completion settings, file indexing limits, and code snippet length through the settings menu.",
      },
    ],
  };

  return (
    <Flex mt={128} direction="column" align="center" justify="center">
      <Flex direction="column" rowGap={24} className="faq" mb={60}>
        <Box mx="auto" className="faq-title">
          Have Questions ?
        </Box>
        <Box className="faq-subtitle">
          Your every burning question is answered swiftly.
        </Box>
        <SegmentedControl
          color="violet"
          value={topic}
          withItemsBorders={false}
          onChange={(data) => {
            setTopic(data);
            setFaqs(faqsStore[data]);
          }}
          data={[
            { label: "Product", value: "Product" },
            { label: "Pricing", value: "Pricing" },
            { label: "Other", value: "Other" },
          ]}
        />
      </Flex>
      <Flex direction="column">
        <Accordion
          radius="md"
          variant="contained"
          className="faq-accordian"
          chevron={<IconPlus />}
        >
          {faqs.map((item) => (
            <Accordion.Item
              className="faq-accordian-child"
              key={item.value}
              value={item.value}
            >
              <Accordion.Control>{item.value}</Accordion.Control>
              <Accordion.Panel>{item.description}</Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>

        <Flex
          direction="column"
          align="center"
          justify="center"
          mt={198}
          mb={240}
        >
          <Box pos="absolute" className="connect-with-us-overlay"></Box>

          <Box className="connect-with-us-title" mb={24}>
            Connect with us
          </Box>

          <Text
            className="connect-with-us-subtitle"
            align="center"
            mb={48}
            mx={8}
          >
            Subscribe to our newsletter for the latest updates and exclusive
            offers.
          </Text>

          <Flex align="center" columnGap={12}>
            <Flex align="center">
              <Input
                className="connect-with-us-input"
                variant="unstyled"
                leftSection={
                  <Box ml={4}>
                    <IconMail stroke={2} />
                  </Box>
                }
                placeholder="Enter your email address"
              />
            </Flex>
            <Button color="violet" size="md" className="join-button">
              Join
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

// ################################################################################

// ################################################################################
// Footer
// ################################################################################

const Footer = () => {
  const columnsData = [
    {
      title: "Product",
      items: ["Features", "Pricing", "Demo", "Doc"],
    },
    {
      title: "Company",
      items: ["About", "Blogs", "Contact"],
    },
    {
      title: "Legal",
      items: ["Features", "Pricing", "Demo", "Doc"],
    },
  ];

  const Column = ({ title, items }) => (
    <Flex w="100%" direction="column">
      <Text color="#ababab" mb={24}>
        {title}
      </Text>
      {items.map((item, index) => (
        <Text key={index} color="#fff" mb={16}>
          {item}
        </Text>
      ))}
    </Flex>
  );

  return (
    <Flex px={80} pt={60} direction="column">
      <Flex
        direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
        rowGap={32}
        className="footer-container"
      >
        <Flex direction="column" miw={260} w="100%" mr={32}>
          <Flex mb={16} align="flex-start">
            <img src="/fynix-logo-white.png" />
          </Flex>
          <Box>
            <Text color="#ababab">
              Fynix is an AI-powered platform that accelerates the software
              development lifecycle, helping developers code faster, smarter,
              and error-free. With real-time assistance and intelligent
              insights, Fynix enhances productivity and streamlines development
              processes.
            </Text>
          </Box>
        </Flex>
        <Flex w="100%" columnGap={12}>
          {columnsData.map((column, index) => (
            <Column key={index} title={column.title} items={column.items} />
          ))}
        </Flex>
      </Flex>
      <Flex
        w="100%"
        mt={72}
        className="copywrite-container"
        style={{
          borderTop: "1px solid #2F2F2F",
          justifyContent: "space-between",
        }}
        mb={16}
      >
        <Flex py={16} className="footer-copyright">
          <Text color="#ababab">
            Copyright © 2024 Fynix. All rights reserved.
          </Text>
        </Flex>
        <Flex align="center" className="footer-social-links" columnGap={8}>
          <Box p={12}>
            <IconBrandX stroke={2} color="#ababab" />
          </Box>
          <Box p={12}>
            <IconBrandInstagram stroke={2} color="#ababab" />
          </Box>
          <Box p={12}>
            <IconBrandLinkedin stroke={2} color="#ababab" />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

// ################################################################################

const LandingPage = () => {
  const trustedCompanies = [
    "/boltic.png",
    "/pixelbin.png",
    "/fynd.png",
    "/fynd-platform.png",
    "/openapi.png",
  ];

  return (
    <Box w="100%" className="parent-wrapper">
      <Box className="child-wrapper">
        <Navbar />
        <Flex direction="column" mb={80}>
          <Flex justify="center">
            <Box
              pos="absolute"
              mt={160}
              className="main-gradient-overlay"
            ></Box>
          </Flex>

          <Flex justify="center" w="100%" px={22} mb={80}>
            <Flex mt={108} maw={1017} direction="column" align="center" p={28}>
              <Badge className="badge" variant="transparent" mb={4}>
                <Flex
                  align="center"
                  gap={8}
                  style={{
                    textTransform: "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                  >
                    <path
                      d="M2.47169 9.35107L0.182119 14.2288C0.110447 14.3763 0.186353 14.4689 0.426709 14.4225L2.29036 14.0182C2.32725 14.0052 2.36787 14.0084 2.40236 14.0268C2.43679 14.0452 2.46196 14.0772 2.47169 14.1151L3.31076 15.8336C3.39084 16.019 3.48362 16.0569 3.55529 15.9095L5.99243 10.8043L2.47169 9.35107Z"
                      fill="#DE7818"
                    />
                    <path
                      d="M9.52881 9.35107L11.8184 14.2288C11.8943 14.3763 11.8142 14.4689 11.5738 14.4225L9.71014 14.0182C9.67325 14.0057 9.63281 14.0091 9.59848 14.0274C9.56416 14.0457 9.53894 14.0775 9.52881 14.1151L8.68974 15.8337C8.60966 16.019 8.52111 16.0569 8.44521 15.9095L6.00806 10.8043L9.52881 9.35107Z"
                      fill="#DE7818"
                    />
                    <path
                      d="M5.31484 12.2235C5.31484 12.2277 5.30638 12.2235 5.29797 12.2235C4.02039 12.082 2.82261 11.5325 1.88264 10.6565C1.8784 10.6524 1.86576 10.6481 1.87 10.6439L1.97542 10.4207C1.97959 10.4123 1.98806 10.4333 1.99229 10.4375C2.89457 11.2757 4.14687 11.8023 5.42867 11.9581C5.43291 11.9581 5.44132 11.9581 5.44132 11.9624L5.31484 12.2235Z"
                      fill="#B35454"
                    />
                    <path
                      d="M5.99999 11.9879C9.31373 11.9879 12 9.30431 12 5.99392C12 2.68359 9.31373 0 5.99999 0C2.68625 0 0 2.68359 0 5.99392C0 9.30431 2.68625 11.9879 5.99999 11.9879Z"
                      fill="#F0CD0A"
                    />
                    <path
                      d="M5.99644 11.1624C8.85372 11.1624 11.1701 8.84846 11.1701 5.99401C11.1701 3.13962 8.85372 0.825684 5.99644 0.825684C3.13915 0.825684 0.822876 3.13962 0.822876 5.99401C0.822876 8.84846 3.13915 11.1624 5.99644 11.1624Z"
                      fill="#D2B309"
                    />
                    <path
                      d="M6.14092 11.1426C8.90508 11.1426 11.1459 8.90405 11.1459 6.14269C11.1459 3.38133 8.90508 1.14282 6.14092 1.14282C3.37676 1.14282 1.13599 3.38133 1.13599 6.14269C1.13599 8.90405 3.37676 11.1426 6.14092 11.1426Z"
                      fill="#CCB011"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.97516 8.43132H7.2052V3.59985H5.97516L4.74512 4.43688V5.49493L5.91149 4.70808H5.97516V8.43132Z"
                      fill="#FFF6CA"
                    />
                    <path
                      d="M7.41382 1.76514C9.33393 2.22308 10.7045 3.91536 10.752 5.88684C10.7995 7.85832 9.51205 9.61454 7.61619 10.1643C8.77994 9.27546 9.55158 7.75906 9.55158 6.03632C9.55158 4.21661 8.69139 2.62862 7.41382 1.76514Z"
                      fill="white"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M6.68982 12.2239C6.69406 12.2281 6.70247 12.2239 6.7067 12.2239C8.01802 12.0596 9.22392 11.5331 10.1305 10.6654C10.1347 10.6611 10.1473 10.6569 10.1431 10.6527L10.0377 10.4295C10.0335 10.421 10.0251 10.4421 10.0208 10.4463C9.1185 11.2846 7.86204 11.8026 6.576 11.9585C6.57176 11.9585 6.56335 11.9585 6.56335 11.9627L6.68982 12.2239Z"
                      fill="#B35454"
                    />
                  </svg>
                  <Text fw={500} className="badge-text">
                    #1 Product on Product Hunt
                  </Text>
                </Flex>
              </Badge>

              <section className="main-section">
                <Text className="main-title">
                  Code Faster, Smarter, and Error-Free with Fynix AI Code
                  Assistant
                </Text>

                <Text className="main-subtitle">
                  Boost your development efficiency with intelligent features
                  designed to simplify your coding process.
                </Text>
              </section>

              <Flex justify="center" gap={16} mt={28}>
                <Button leftSection={<IconWand stroke={2} />} color="violet">
                  Get Started
                </Button>
                <Button
                  leftSection={<IconCalendar stroke={2} />}
                  variant="outline"
                  color="violet"
                >
                  Book a Demo
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex justify="center">
            <Box className="main-card" mx={24}>
              <Box className="main-card-inner">
                <video controls style={{ borderRadius: "inherit" }}>
                  <source src="/main-sample-video.mp4" type="video/mp4" />
                </video>
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Box maw={920} mx="auto" mb={24}>
          <Flex direction="column" mt={80} w="100%" align="center">
            <Flex
              justify="center"
              mb={20}
              className="trusted-compaines-tagline"
            >
              Trusted By 1500+ Engineers At
            </Flex>
            <Flex
              justify="center"
              direction={{ xs: "column", sm: "column", md: "row" }}
              mt={20}
              columnGap={64}
              rowGap={24}
              className="trusted-companies-images-container"
            >
              {trustedCompanies.map((companyPath) => (
                <Box
                  className="trusted-companies-images"
                  style={{
                    background: `url(${companyPath}) 1.996px 2.068px / 98.118% 88.889% no-repeat`,
                  }}
                ></Box>
              ))}
            </Flex>
          </Flex>
        </Box>

        <Flex columnGap={32} justify="center">
          <Box className="l-animation">
            <Box className="inner">
              <Box className="inner-child"></Box>
            </Box>
          </Box>
          <Flex direction="column" align="center" h="auto">
            <Flex columnGap={32} align="center">
              <Box className="inner-l-animation">
                <Box className="inner">
                  <Box className="inner-child"></Box>
                </Box>
              </Box>
              <Box className="inner-r-animation">
                <Box className="inner">
                  <Box className="inner-child"></Box>
                </Box>
              </Box>
            </Flex>
            <Flex
              justify="center"
              align="center"
              mt={30}
              className="fynix-offers"
            >
              What Fynix Offers
            </Flex>
          </Flex>
          <Box className="r-animation">
            <Box className="inner">
              <Box className="inner-child"></Box>
            </Box>
          </Box>
        </Flex>

        <Flex direction="column" align="center">
          <Box w="80%">
            <Flex
              justify="center"
              className="offers-cards"
              direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
              mt={72}
              columnGap={32}
              rowGap={32}
              mb={40}
              pos="relative"
            >
              <FynixOffersCard
                image="/vscode.png"
                title="AI Code Assistant"
                subTitle="Code with ease with Fynix Copilot as your VS Code & Pycharm extension that assists you in enhancing your codes"
              />
              <FynixOffersCard
                image="/dashboard.png"
                title="Code Quality Agent"
                subTitle="Gain insights on pull requests and repositories reviewed with our productivity tracking developer’s dashboard."
              />
            </Flex>
          </Box>

          <Box w="80%" mt={72}>
            <Flex
              justify="center"
              direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
              columnGap={32}
              rowGap={32}
              className="offers-cards"
            >
              <NotifyMeCards
                title="AI Document Creator"
                subTitle="Helps managers build go-to-market strategy plans, OKRs documents, product launch checklists, PRDs, BRDs, and many more."
              />
              <NotifyMeCards
                title="Mermaid Diagram Generator"
                subTitle="Helps users generate code-based diagrams that represent structures and processes using easy to modify Markdown texts."
              />
              <NotifyMeCards
                title="Quip Chatbot"
                subTitle="Helps users to submit a quip folder and ask any questions to a bot with the context directly from the folder."
              />
            </Flex>
          </Box>
        </Flex>

        {/* Features */}
        <Features title="How Fynix AI Code Assistant Empowers Devs" />

        {/* Workflow */}
        <Flex mt={180} mb={120} direction="column" align="center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.5 8C21.3954 8 20.5 8.89543 20.5 10C20.5 11.1046 21.3954 12 22.5 12H26.5C27.6046 12 28.5 11.1046 28.5 10C28.5 8.89543 27.6046 8 26.5 8H22.5ZM16.8414 8C17.6651 5.66962 19.8876 4 22.5 4H26.5C29.1124 4 31.3349 5.66962 32.1586 8H34.5C36.0913 8 37.6174 8.63214 38.7426 9.75736C39.8679 10.8826 40.5 12.4087 40.5 14V38C40.5 39.5913 39.8679 41.1174 38.7426 42.2426C37.6174 43.3679 36.0913 44 34.5 44H14.5C12.9087 44 11.3826 43.3679 10.2574 42.2426C9.13214 41.1174 8.5 39.5913 8.5 38V14C8.5 12.4087 9.13214 10.8826 10.2574 9.75736C11.3826 8.63214 12.9087 8 14.5 8H16.8414ZM16.8414 12H14.5C13.9696 12 13.4609 12.2107 13.0858 12.5858C12.7107 12.9609 12.5 13.4696 12.5 14V38C12.5 38.5304 12.7107 39.0391 13.0858 39.4142C13.4609 39.7893 13.9696 40 14.5 40H34.5C35.0304 40 35.5391 39.7893 35.9142 39.4142C36.2893 39.0391 36.5 38.5304 36.5 38V14C36.5 13.4696 36.2893 12.9609 35.9142 12.5858C35.5391 12.2107 35.0304 12 34.5 12H32.1586C31.3349 14.3304 29.1124 16 26.5 16H22.5C19.8876 16 17.6651 14.3304 16.8414 12ZM18.5 22C19.6046 22 20.5 22.8954 20.5 24V34C20.5 35.1046 19.6046 36 18.5 36C17.3954 36 16.5 35.1046 16.5 34V24C16.5 22.8954 17.3954 22 18.5 22ZM30.5 26C31.6046 26 32.5 26.8954 32.5 28V34C32.5 35.1046 31.6046 36 30.5 36C29.3954 36 28.5 35.1046 28.5 34V28C28.5 26.8954 29.3954 26 30.5 26ZM24.5 30C25.6046 30 26.5 30.8954 26.5 32V34C26.5 35.1046 25.6046 36 24.5 36C23.3954 36 22.5 35.1046 22.5 34V32C22.5 30.8954 23.3954 30 24.5 30Z"
              fill="url(#paint0_linear_528_40)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.5 8C21.3954 8 20.5 8.89543 20.5 10C20.5 11.1046 21.3954 12 22.5 12H26.5C27.6046 12 28.5 11.1046 28.5 10C28.5 8.89543 27.6046 8 26.5 8H22.5ZM16.8414 8C17.6651 5.66962 19.8876 4 22.5 4H26.5C29.1124 4 31.3349 5.66962 32.1586 8H34.5C36.0913 8 37.6174 8.63214 38.7426 9.75736C39.8679 10.8826 40.5 12.4087 40.5 14V38C40.5 39.5913 39.8679 41.1174 38.7426 42.2426C37.6174 43.3679 36.0913 44 34.5 44H14.5C12.9087 44 11.3826 43.3679 10.2574 42.2426C9.13214 41.1174 8.5 39.5913 8.5 38V14C8.5 12.4087 9.13214 10.8826 10.2574 9.75736C11.3826 8.63214 12.9087 8 14.5 8H16.8414ZM16.8414 12H14.5C13.9696 12 13.4609 12.2107 13.0858 12.5858C12.7107 12.9609 12.5 13.4696 12.5 14V38C12.5 38.5304 12.7107 39.0391 13.0858 39.4142C13.4609 39.7893 13.9696 40 14.5 40H34.5C35.0304 40 35.5391 39.7893 35.9142 39.4142C36.2893 39.0391 36.5 38.5304 36.5 38V14C36.5 13.4696 36.2893 12.9609 35.9142 12.5858C35.5391 12.2107 35.0304 12 34.5 12H32.1586C31.3349 14.3304 29.1124 16 26.5 16H22.5C19.8876 16 17.6651 14.3304 16.8414 12ZM18.5 22C19.6046 22 20.5 22.8954 20.5 24V34C20.5 35.1046 19.6046 36 18.5 36C17.3954 36 16.5 35.1046 16.5 34V24C16.5 22.8954 17.3954 22 18.5 22ZM30.5 26C31.6046 26 32.5 26.8954 32.5 28V34C32.5 35.1046 31.6046 36 30.5 36C29.3954 36 28.5 35.1046 28.5 34V28C28.5 26.8954 29.3954 26 30.5 26ZM24.5 30C25.6046 30 26.5 30.8954 26.5 32V34C26.5 35.1046 25.6046 36 24.5 36C23.3954 36 22.5 35.1046 22.5 34V32C22.5 30.8954 23.3954 30 24.5 30Z"
              fill="url(#paint1_linear_528_40)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_528_40"
                x1="24.5"
                y1="4"
                x2="24.5"
                y2="44"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8455FF" />
                <stop offset="1" stop-color="#989898" stop-opacity="0.4" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_528_40"
                x1="24.5"
                y1="4"
                x2="24.5"
                y2="44"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8455FF" />
                <stop offset="1" stop-color="#989898" stop-opacity="0.4" />
              </linearGradient>
            </defs>
          </svg>

          <Box className="workflow-title">
            How Code Quality Agent Enhances Workflow
          </Box>

          <Flex
            mt={60}
            columnGap={32}
            h={524}
            w="80%"
            className="workflow-container"
            direction={{ xs: "column", sm: "column", md: "row" }}
            rowGap={24}
          >
            <Flex direction="column" className="fynix-offers-card" p={40}>
              <Flex className="workflow-badge" px={12} py={4} w="fit-content">
                <IconBoltFilled color="#8455FF" />
                Available for enterprise
              </Flex>

              <Box mt={16} className="workflow-subtitle">
                Fine-tuned code reviews using configurable instructions.
              </Box>
            </Flex>
            <Flex
              direction="column"
              className="fynix-offers-card"
              pt={40}
              px={32}
            >
              <Flex className="workflow-badge" px={12} py={4} w="fit-content">
                <IconBoltFilled color="#8455FF" />
                Available for enterprise
              </Flex>
              <Box mt={16} className="workflow-subtitle">
                A Centralized user level dashboard to track pull requests,
                repositories review details, and developer rating
                configurations.
              </Box>
            </Flex>
          </Flex>
        </Flex>

        {/* Feedback */}
        <Feedback />

        {/* FAQ */}
        <Faq />

        {/* Footer */}
        <Footer />
      </Box>
    </Box>
  );
};

export default LandingPage;
