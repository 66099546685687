import createRoutes from '../../utils/utilities';
import ConfigurePage from './ConfigureTokens';
import OrganizationPage from './OrgSelection';
import NoWorkspaceFound from './NoWorkspace';

export const commonRoutes = {
    configurePage: {
        name: "Configure",
        path: "/config",
        component: ConfigurePage,
        private: true
    },
    organizationSelection: {
        name: "Organization Selection",
        path: "/org-selection",
        component: OrganizationPage,
        private: true
    },
    noWorkspaceFound: {
        name: "No Workspace Found",
        path: "/no-workspace-found",
        component: NoWorkspaceFound,
        private: true
    },
};

const CommonRoutes = createRoutes({ routes: commonRoutes });
export default CommonRoutes;