import React from "react";
import { Box, VStack, Link, Text, Image, Flex, Button, Icon } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi"; // Import an icon from react-icons
import { useNavigate, useLocation } from "react-router-dom";
import { baseNejiUrl } from "../../utils/constants";

const Navbar = () => {
	const navigate = useNavigate();
	const location = useLocation(); // Get the current path

	const handleLogout = async () => {
		try {
			const response = await fetch(`${baseNejiUrl}authentication/logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					credentials: 'include'
				}
			});

			if (response.ok) {
				// Remove tokens and other related data
				localStorage.removeItem("token");
				localStorage.removeItem("email");
				navigate("/");
				console.log("User has logged out");
			} else {
				console.error('Failed to log out');
			}
		} catch (error) {
			console.error('An error occurred during logout:', error);
		}
	};

	// Function to determine if the current route is active
	const isActiveRoute = (route) => location.pathname === route;

	return (
		<Box
			bg="transparent" // Make the background transparent
			w="40vh"
			h="100vh"
			px={4}
			py={8}
			position="absolute"
			borderRight="2px solid lightgray" // Add a light border line on the right side
		>
			<Flex flexDirection="column" h="100%">
				<VStack spacing={5} align="stretch" alignItems={"center"} flex="1">
					<Flex alignItems="center">
						<svg width="50" height="50" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_1073_26659)">
							<path d="M5.14498 0.59082H1.74123H1.73073V0.60832L0.00523376 3.64282L1.73073 6.59332L3.46148 3.55007H11.522L13.9947 0.59082H5.14498Z" fill="#A380FF"/>
							<path d="M0.909912 10.459L2.63541 13.4095L4.36616 10.3662L5.81866 7.81472H9.00366L10.7992 4.85547H4.10366L0.909912 10.459Z" fill="#A380FF"/>
							</g>
							<defs>
							<clipPath id="clip0_1073_26659">
							<rect width="13.9895" height="12.8188" fill="white" transform="translate(0.00523376 0.59082)"/>
							</clipPath>
							</defs>
						</svg>
						<Text fontSize="2xl" fontWeight="bold" color="#A380FF">
							FyniX
						</Text>
					</Flex>
					<Link
						py={3}
						px={20}
						rounded="md"
						href="/my-brds"
						// Apply a different background color if the route is active
						bg={isActiveRoute("/my-brds") ? "purple.100" : "transparent"}
						_hover={{ bg: "gray.100" }}
					>
						My Design Docs
					</Link>
				</VStack>
				<Flex justifyContent="center" mt="auto"> {/* Align at the bottom */}
					<Button
						leftIcon={<Icon as={FiLogOut} />} // Use the logout icon
						colorScheme="red" // Red color to signify a critical action like logging out
						variant="solid"
						onClick={handleLogout}
					>
						Logout
					</Button>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Navbar;
