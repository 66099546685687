import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { defaultScreenshot } from "../../utils/screenshot";
import { baseDevasUrl, baseDevasWebsocketUrl } from "../../utils/constants"
import {
    Box,
    Button,
    Input,
    Text,
    VStack,
    HStack,
    Flex,
    Heading,
    Divider,
    useColorModeValue,
    useColorMode,
    IconButton,
    Spacer,
    Image,
    Icon,
    createIcon,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { ArrowForwardIcon, DownloadIcon } from "@chakra-ui/icons";
import MarkdownEditor from '@uiw/react-markdown-editor';
import ChatComponent from './../common/ChatComponent';
import CustomBreadcrumb from '../common/CustomBreadcrumb';
import { genericRoutes } from '../routes';
import { documentRoutes } from './routes';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.7);
  }
`;
const previewPropsMarkdownEditor = {
    wrapperElement: {
      style: { backgroundColor: 'lightblue' }, // Change 'lightblue' to your desired color
      'data-color-mode': 'light', // Example of using custom data attributes
    },
  };
// Create a custom icon component using the provided SVG
const AttachmentIcon = createIcon({
    displayName: 'AttachmentIcon',
    viewBox: '0 0 36 36',
    path: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none" width="36" height="36">
        <rect width="36" height="36" rx="6" fill="#FF5588" />
        <path
          d="M19.6663 9.66663H12.9997C12.5576 9.66663 12.1337 9.84222 11.8212 10.1548C11.5086 10.4673 11.333 10.8913 11.333 11.3333V24.6666C11.333 25.1087 11.5086 25.5326 11.8212 25.8451C12.1337 26.1577 12.5576 26.3333 12.9997 26.3333H22.9997C23.4417 26.3333 23.8656 26.1577 24.1782 25.8451C24.4907 25.5326 24.6663 25.1087 24.6663 24.6666V14.6666L19.6663 9.66663Z"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.667 9.66663V14.6666H24.667"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3337 18.8334H14.667"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3337 22.1666H14.667"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3337 15.5H15.5003H14.667"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  });

function DocumentCreatorPage(props) {
    const productId = 2
    const { projectId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var threadId = queryParams.get('c');
    const { brdData } = location.state || { brdData: '' };
    const { toggleColorMode, setColorMode, colorMode } = useColorMode("light");
    let brdSentOnce = false;
    
    const [socket, setSocket] = useState(null);
    const [userMessage, setUserMessage] = useState("");
    const [file, setFile] = useState(null);
    const [externalLink, setExternalLink] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [markdownContent, setMarkdownContent] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const initialColorModeSet = useRef(false);
    const [url, setUrl] = useState(null);
    const [screenshot, setScreenshot] = useState(defaultScreenshot);
    const [pastThreads, setPastThreads] = useState([]);
    const [chatConfig, setchatConfig] = useState('');
    const getThreadsApi = `${baseDevasUrl}projects/${projectId}/threads?product_id=${productId}`;
    const [editorVisible, setEditorVisible] = useState(false);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [hasAccess, setHasAccess] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const breadcrumbItems = [
        { label: genericRoutes.products.name, path: genericRoutes.products.path, isCurrentPage: false },
        { label: documentRoutes.documentsProjects.name, path: documentRoutes.documentsProjects.path, isCurrentPage: false },
        { label: documentRoutes.documentProjectPage.name, path: documentRoutes.documentProjectPage.path, pathParams: { projectId },
            isCurrentPage: true }
    ];


    let tempchatConfig = {
        communicationURL: brdData.length > 0 ? `${baseDevasWebsocketUrl}devas/chat/prd_builder/${projectId}` : '',
        communicationMethod: 'websocket',
        getCommunicationURL: (thread_id) => `${baseDevasWebsocketUrl}devas/chat/prd_builder/${thread_id}`,
        isCrateNewSocket: false,
        socket: socket,
        colors: {
            background: {
                light: "gray.50",
                dark: "gray.800"
            },
            userMessageBackground: {
                light: "green.100",
                dark: "green.800"
            },
            assistantMessageBackground: {
                light: "blue.100",
                dark: "blue.800"
            },
            planMessageBackground: {
                light: "blue.100",
                dark: "blue.900"
            },
            cardBackground: {
                light: "white",
                dark: "gray.700"
            }
        }
    };

    const fetchPastThreads = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(getThreadsApi, {credentials: 'include'});
            if (!response.ok) {
                throw new Error('Failed to fetch past threads');
            }
            const response_data = await response.json();
            console.log("response_data >> ", response_data);
            setIsLoading(false);
            if (response_data.data) {
                const threads = response_data.data.threads
                console.log("Threads >> ", threads);
                if (threads.length > 0){
                    threads.sort((x, y) => y.id - x.id);
                    setPastThreads(threads);
                    if (!threadId){
                        threadId = threads[0].id
                        const newUrl = `${window.location.pathname}?c=${threadId}`;
                        window.history.pushState({ path: newUrl }, '', newUrl);
                    } 
                    loadThreadMessages(threadId);
                    tempchatConfig.communicationURL = `${baseDevasWebsocketUrl}devas/chat/prd_builder/${threadId}`
                    setchatConfig(tempchatConfig);
                }
            } else {
                setHasAccess(false);
            }
        } catch (error) {
            console.error('Error fetching past threads:', error);
        }
    };

    const getProjectDetails = async () => {
        try {
            const response = await fetch(getThreadsApi);
            if (!response.ok) {
                throw new Error('Failed to fetch past threads');
            }
            const response_data = await response.json();
            if (response_data.data.length > 0) {
                response_data.data.sort((x, y) => y.id - x.id);
                setPastThreads(response_data.data);
                loadThreadMessages(response_data.data[0].id);
                tempchatConfig.communicationURL = `${baseDevasWebsocketUrl}devas/chat/prd_builder/${response_data.data[0].id}`
                setchatConfig(tempchatConfig);
            }
        } catch (error) {
            console.error('Error fetching past threads:', error);
        }
    };

    const loadThreadMessages = (thread_id) => {
        if (thread_id != threadId){
            threadId = thread_id;
            const newUrl = `${window.location.pathname}?c=${thread_id}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
        }
        setMarkdownContent("")
        setChatMessages([]);
        fetch(`${baseDevasUrl}threads/${thread_id}/messages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(response => {
                if (response.success && response.data.length > 0) {
                    const messages = response.data
                    // console.log('messages >>', messages);
                    for (const message of messages) {
                        // console.log("message >>", message.content);
                        // console.log("chatMessages >>", chatMessages);
                        if (message.is_json) {
                            const data = JSON.parse(message.content);
                            // console.log("all data=",data)
                             if(data.window === 'markdown'){
                                // console.log("markdown content",data.message)
                                setMarkdownContent(data.message);
                            }
                            else if (data.window === 'browser') {
                                updateBrowser(data);
                            } else if (data.type === 'plan') {
                                receiveMessage(data.message, 'plan');
                            } else if (data.type === 'attachment') {
                                receiveMessage(data.message, 'attachment');
                            } else {
                                receiveMessage(data.message, 'text');
                            }
                            
                        }
                         else 
                            setChatMessages((previousChat) => [...previousChat, message]);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };
    function extractValues(input) {
        // Parse the JSON string to an object
        const parsedObject = JSON.parse(input);
        
        // Extract the user_input value
        const userInput = parsedObject.user_input;
    
        // Use a regular expression to find the file_name within the user_input
        const fileNameMatch = userInput.match(/file_name\\\"\=\\\"([^\"]+)/);
        const fileName = fileNameMatch ? fileNameMatch[1] : null;
    
        return { userInput, fileName };
    }
    

    const createChatThread = async (name) => {
        setChatMessages([]);
        setMarkdownContent("")
        const response = await fetch(`${baseDevasUrl}threads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "requested_by": localStorage.getItem("email"),
                "alternate_id": `${projectId}`
            }),
        });

        if (response.ok) {
            const response_data = await response.json();
            await fetchPastThreads();
            const thread_id = response_data.data.thread_id;
            return thread_id;
        }
    };

    const handleInputChange = (event) => {
        setUrl(event.target.value);
    };

    const updateBrowser = (data) => {
        if (data.type === "link") {
            setUrl(data.message.link);
        } else if (data.type === "image") {
            setScreenshot(data.message.image);
        }
    };

    const receiveMessage = (message, type) => {
        // console.log("receiveMessage",message)
        if (type === 'plan') {
            const steps = Object.entries(message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            setChatMessages((prev) => [...prev, { role: "assistant", content: steps, type: 'plan' }]);
        }
        // if (type === 'attachment') {
        //     console.log("type_attachement")
        //     const steps = Object.entries(message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
        //     setChatMessages((prev) => [...prev, { role: "assistant", content: steps, type: 'attachment' }]);
        // } 
        else {
            setChatMessages((prev) => [...prev, { role: "assistant", content: message, type: 'text' }]);
        }
    };

    const inputParser = (input) => {
        return input.trim();
    };

    const outputParser = (output_data) => {
        const data = JSON.parse(output_data);
        // console.log("outputParserData",data)
        if (data.window === 'markdown') {
            setMarkdownContent(data.message);
        } 
        else if (data.type === 'plan') {
            const steps = Object.entries(data.message).map(([key, value], index) => `Step ${index + 1}: ${value}`).join("\n");
            return { role: "assistant", content: steps, type: 'plan',  meta: data.meta || {}  };
        } else {
            return { role: "assistant", content: data.message, type: 'text',  meta: data.meta || {} };
        }
    };

    const renderMessages = (chatMessages) => {
        return chatMessages.map((msg, index) => {
            // Parse the msg.content if it's a stringified JSON
            let tempMessage;
            try {
                tempMessage = typeof msg.content === 'string' ? JSON.parse(msg.content) : msg.content;
            } catch (error) {
                tempMessage = { type: 'error', content: 'Error parsing message content' };
            }
    
            // console.log("msg", index, tempMessage);
    
            if (msg.type === 'plan') {
                return (
                    <Box key={index} bg={planMessageBgColor} p={4} borderRadius="md" alignSelf="flex-start">
                        {tempMessage.content.split("\n").map((line, idx) => (
                            <Text key={idx} fontSize="md" mb={2}>{line}</Text>
                        ))}
                    </Box>
                );
            } else if (tempMessage.type === 'attachment') {
                return (
                    <Flex direction="column" alignSelf="flex-end" m={0} p={0} width="auto">
                    <Flex direction="row" alignItems="flex-end" mb={2} pb={2} pr={5} pl={2} pt={1} alignSelf = "end" bg={cardBgColor} 
                        style={{border: `1px solid ${bgColor}`, borderRadius: '10px', boxShadow: "0 2px 6px rgba(0,0,0,0.2)"}}>
                        <Icon as={AttachmentIcon} boxSize={10} mr={2}/>
                        <Box>
                            <Text fontWeight="500" isTruncated>{tempMessage.file_name}</Text>
                            <Text color="gray.400" fontSize="sm" isTruncated>PDF</Text>
                        </Box>
                    </Flex>
                    <Box
                        key={index} // Ensure 'index' is defined in the component's parameters or state.
                        bg={msg.role === "user" ? userMessageBgColor : assistantMessageBgColor}
                        p={3}
                        borderRadius="md"
                        alignSelf="flex-end"
                        maxW="none"
                        mr={0} // Adjust right margin if necessary
                    >
                        <Text mt={0}>{tempMessage.message}</Text>
                    </Box>
                </Flex>
                  );
            } else {
                // console.log("message-user",msg)
                return (
                    <Text key={index} bg={msg.role === "user" ? userMessageBgColor : assistantMessageBgColor} p={3} borderRadius="md" alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}>
                        {msg.content}
                    </Text>
                );
            }
        });
    };
    
    

    useEffect(() => {
        setColorMode("light");
        setchatConfig(tempchatConfig);
        fetchPastThreads();
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleLinkChange = (event) => {
        setExternalLink(event.target.value);
    };

    const handleSendAttachment = () => {
        // console.log("Sending file:", file);
        // console.log("Sending link:", externalLink);
    };

    const downloadPDF = async () => {
        // console.log("calling the markdown to pdf api");
        const formData = new FormData();
        formData.append('markdownContent', markdownContent);
        let attempts = 0;
        const maxAttempts = 3; // Maximum number of attempts
        while (attempts < maxAttempts) {
            try {
                const response = await fetch(`${baseDevasUrl}devas/util/markdown_to_pdf`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    // Create a blob from the PDF stream
                    const blob = await response.blob();
                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(blob);
                    // Create a temporary anchor element and trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = "downloaded.pdf"; // You can name the file anything you want
                    document.body.appendChild(a); // Append the anchor to the body
                    a.click(); // Simulate click on the anchor to trigger the download
                    window.URL.revokeObjectURL(url); // Clean up by revoking the blob URL
                    a.remove(); // Remove the anchor from the body
                    return; // Exit the function after successful download
                } else {
                    console.error("Failed to download PDF. Status: ", response.status);
                    attempts++;
                }
            } catch (error) {
                console.error("An error occurred: ", error);
                attempts++;
            }
        }
        console.error("Failed to download PDF after " + maxAttempts + " attempts.");
    };
    const handleMarkdownChange = (editor, data, value) => {
        setMarkdownContent(value);
        // Check if the editor is in preview mode and update state
        setIsPreviewMode(data.viewMode === 'preview');
        setEditorVisible(!editorVisible);
    };
    

    const sendMessage = () => {
        if (socket && socket.readyState === WebSocket.OPEN && userMessage.trim()) {
            const message = { role: "user", content: userMessage };
            socket.send(JSON.stringify(userMessage));
            setChatMessages((prev) => [...prev, message]);
            setUserMessage("");
            setIsTyping(true);
        }
    };

    const customPreviewIcon = {
        name: 'preview',
        keyCommand: 'preview',
        button: {'id': 'preview-btn', 'class': 'active'},
        icon: (
            <svg width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
        ),
        disableCopy: true,
        className: "preview-btn",
        execute: (state, api) => {
            const editor = document.getElementsByClassName('md-editor-content-editor')[0]
            editor.style.width = !isPreviewMode ? 'calc(0%)' : '100%';
            // editor.style.display = !isPreviewMode ? 'none' : 'block';
            const preview = document.getElementsByClassName('md-editor-preview')[0]
            preview.style.width = !isPreviewMode ? '100%' : '0%';
            // preview.style.display = !isPreviewMode ? 'block' : 'none';
            const previewBtn = document.getElementById('preview-btn')
            previewBtn.innerHTML = !isPreviewMode ? 
            '<svg width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>' : 
            '<svg fill="currentColor" viewBox="0 0 576 512" height="16" width="16"><path d="M279.6 160.4c2.8-.3 5.6-.4 8.4-.4 53 0 96 42.1 96 96 0 53-43 96-96 96-53.9 0-96-43-96-96 0-2.8.1-5.6.4-8.4 9.3 4.5 20.1 8.4 31.6 8.4 35.3 0 64-28.7 64-64 0-11.5-3.9-22.3-8.4-31.6zm201-47.8c46.8 43.4 78.1 94.5 92.9 131.1 3.3 7.9 3.3 16.7 0 24.6-14.8 35.7-46.1 86.8-92.9 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.58-80.6C48.62 355.1 17.34 304 2.461 268.3a31.967 31.967 0 0 1 0-24.6C17.34 207.1 48.62 156 95.42 112.6 142.5 68.84 207.2 32 288 32c80.8 0 145.5 36.84 192.6 80.6zM288 112c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144z"></path></svg>'
            setIsPreviewMode(!isPreviewMode);
        },
    };

    const bgColor = useColorModeValue("gray.100", "gray.800");
    const cardBgColor = useColorModeValue("white", "gray.700");
    const inputBgColor = useColorModeValue("white", "gray.600");
    const userMessageBgColor = useColorModeValue("green.100", "green.800");
    const assistantMessageBgColor = useColorModeValue("blue.100", "blue.800");
    const planMessageBgColor = useColorModeValue("blue.100", "blue.900");

    return (
        <Box>
            <Box
                h="100vh"
                w="full"
                position="absolute"
                zIndex="overlay"
                bgColor={planMessageBgColor}
                justifyContent="center"
                alignItems="center"
                display={hasAccess ?'none': 'flex'}
            >
                <VStack spacing={4} textAlign="center" color="white">
                <Text fontSize="40px" color="red.400" fontWeight="500" fontFamily="Lucida Console, Courier, monospace">Oops !</Text>
                    <HStack mb={-10}>
                        <Text fontSize="200px" color="gray.400" fontWeight="1000">4</Text>
                        <Image src="/restriction.png" alt="Access Denied" w="180px" mr={-2} ml={-2}/>
                        <Text fontSize="200px" color="gray.400" fontWeight="1000">3</Text>
                    </HStack>
                    <Text fontSize="40px" color="red.400" fontWeight="500" fontFamily="Lucida Console, Courier, monospace">Access Denied</Text>
                    <Text color="purple.800">You do not have permission to view this page, or the page may not be available.</Text>
                    <Button colorScheme="teal" onClick={() => navigate(-1)}>
                        Go Back
                    </Button>
                </VStack>
            </Box>
            {hasAccess && <Flex h="100vh" p={4} bg={bgColor}>
                <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} height="calc(100vh - 2rem)" overflow="auto">
                    <CustomBreadcrumb items={breadcrumbItems}></CustomBreadcrumb>
                    <ChatComponent
                        chatConfig={chatConfig}
                        inputParser={inputParser}
                        setchatConfig={setchatConfig}
                        outputParser={outputParser}
                        chatMessages={chatMessages}
                        renderMessages={renderMessages}
                        setChatMessages={setChatMessages}
                        loadThreadMessages={(thread_id) => loadThreadMessages(thread_id)}
                        createChatThread={(name) => createChatThread(name)}
                        pastThreads={pastThreads}
                        setPastThreads={setPastThreads}
                        setMarkdownContent={setMarkdownContent}
                    />
                </Flex>
                <Flex direction="column" flex="1" p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBgColor} height="calc(100vh - 2rem)" overflow="auto" >
                    <Flex justifyContent="space-between" alignItems="center" mb={4} mt = {"10px"}>
                        <Heading fontSize="xl">Document</Heading>
                        <Spacer />
                    </Flex>
                    <Flex direction="column" flex="1" overflow="auto">
                    <MarkdownEditor 
                        value={markdownContent}
                        onChange={handleMarkdownChange}
                        height={"800"}
                        visiableDragbar={false}
                        visible={true}
                        previewProps = {previewPropsMarkdownEditor}
                        previewWidth={"100%"}
                        enablePreview={true}
                        // className={isPreviewMode ? "editor-preview-mode" : ""}
                        // toolbarsMode={["preview", customPreviewIcon, "fullscreen"]}
                    />
                    </Flex>
                    <Flex justifyContent="flex-end" alignItems="center" mt={2}>
                        <Spacer mb={10} />
                        {markdownContent && <Button onClick={downloadPDF} colorScheme="purple" mr={1}>Export As PDF <DownloadIcon ml={2}/></Button>}
                    </Flex>
                </Flex>
            </Flex>}
        </Box>
    );
}

export default DocumentCreatorPage;
